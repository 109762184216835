import React, {useContext, useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import './LandingPage.scss';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../utils/UserContext';
import {getJobs, getLikedJobs, likeJobApi} from '../../api/jobsApi';
import LoginMessage from '../../components/Popups/LoginMessage';
import {Helmet} from 'react-helmet';
import GoogleModal from '../../components/Popups/GoogleModal';
import {JobModal, SelectedJobCard} from './LandingPageCards';
import {ROUTES} from '../../utils/ROUTES';
import {Alert, useMediaQuery} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {permColors} from '../../utils/colors';
import {COUNTRY_CODE, getCountryCode} from '../../api/locationApi';
import {AutoComplete, Button, Card, Empty, Spin, Tabs, Tooltip} from 'antd';
import {CloseCircleOutlined, SaveFilled, SaveOutlined, SearchOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {JobCard} from './JobCard';
import {jobsPerPage} from '../BrowseJobsPage/JobsHome';
import {CustomPagination} from '../../utils/MaterialUiThemes';
import {COOKIES} from '../../utils/cookie-names';
import Cookies from 'js-cookie';
import {userExists} from '../../utils/variables/userVariables';
import {useAtom} from 'jotai';
import {jobsAtom, jobsAtomTriggered, likedJobsAtom, selectedJobAtom, totalJobsAtom, totalPagesAtom, currentJobsPageAtom, appliedJobRetriggerAtom} from '../../utils/atoms';
import {Bookmark, BookmarkBorder, BookmarkOutlined, Save} from '@mui/icons-material';
import {clearSearchItem, getSearchHistory, saveSearch} from '../../utils/searchCookies';
import {isFilterOnAtom} from '../../utils/atoms/filterAtoms';

export default function LandingPage() {
	const [selected, setSelected] = useState(true);
	const [jobs, setJobs] = useAtom(jobsAtom);
	const [jobsTriggered, setJobsTriggered] = useAtom(jobsAtomTriggered);
	const [likedJobs, setLikedJobs] = useAtom(likedJobsAtom);
	const [selectedJob, setSelectedJob] = useAtom(selectedJobAtom);
	const [selectedJob2, setSelectedJob2] = useState({});
	const [currentUser] = useContext(UserContext);
	const [localLikedJobs, setLocalLikedJobs] = useState([]);
	const [retrigger, setRetrigger] = useState(false);
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useAtom(currentJobsPageAtom);
	const [message, setMessage] = useState('');
	const [popup, setPopup] = useState(false);
	const [, setSearch] = useState('');
	const [googleModal, setGoogleModal] = useState(false);
	const [totalJobs, setTotalJobs] = useAtom(totalJobsAtom);
	const [totalPages, setTotalPages] = useAtom(totalPagesAtom);
	const [appliedJobTrigger, setAppliedJobTrigger] = useAtom(appliedJobRetriggerAtom);

	const [modalShow1, setModalShow1] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [jobLoading, setJobLoading] = useState(false);
	const smallMatches = useMediaQuery('(max-width: 1350px)');

	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [snackbarSeverity, setSnackbarSeverity] = React.useState('');

	const [countryCode, setCountryCode] = useState('');
	const [searchHistory, setSearchHistory] = useState([]);
	const [activeTab, setActiveTab] = useState('List');
	const [isFilterOn, setIsFilterOn] = useAtom(isFilterOnAtom);

	useEffect(() => {
		const history = getSearchHistory();
		if (history) {
			setSearchHistory(JSON.parse(history).reverse());
		}
	}, [setSearchHistory]);

	const onSearch = (value) => {
		const searchParams = new URLSearchParams();
		searchParams.append('search', value);
		saveSearch(value);
		navigate(`${ROUTES.BROWSE_JOBS}/?${searchParams.toString()}`);
	};

	useEffect(() => {
		if (smallMatches) return;

		// Only set initial selection if there isn't already a selected job
		if (jobs?.length > 0 && !selectedJob?.id) {
			setSelectedJob(jobs[0]);
			setModalShow1(true);
		}

		if (likedJobs?.length > 0 && !selectedJob2?.id) {
			setSelectedJob2(likedJobs[0]);
			setModalShow2(true);
		}
	}, [jobs]);

	async function fetchJobs() {
		setSelectedJob({});
		setJobLoading(true);
		const data = await getJobs(currentPage);
		console.log(data);
		setJobs(data?.results);
		setTotalJobs(data?.count);
		if (data?.results?.length > 0) {
			setSelectedJob(data.results[0]);

			const currentLocation = Cookies.get(COOKIES.current_location);
			if (currentLocation && currentLocation !== ROUTES.HOME) {
				console.log('Navigating to:', currentLocation);
				Cookies.remove(COOKIES.current_location);
				navigate(currentLocation);
			}

			if (Cookies.get(COOKIES.apply_job_redirect)) {
				const jobId = Cookies.get(COOKIES.apply_job_redirect);
				const selectedJob = data?.results?.find((job) => job.id === jobId);
				setSelectedJob(selectedJob);
				Cookies.remove(COOKIES.apply_job_redirect);
			}
		}
		if (data?.count === 0) {
			setSelectedJob({});
		}
		setTotalPages(data?.total_pages);
		setJobLoading(false);
		setJobsTriggered(true); // Set this to true after first fetch
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	const handleAppliedJobUpdate = async () => {
		if (appliedJobTrigger) {
			const currentSelectedJobId = selectedJob?.id;
			const currentPageNumber = currentPage;

			try {
				setSelectedJob({});

				const data = await getJobs(currentPageNumber);
				setJobs(data?.results);
				setTotalJobs(data?.count);

				// Find the updated job after the new data is fetched
				const updatedJob = data?.results?.find((job) => job.id === currentSelectedJobId);

				// Use a small delay to ensure the jobs list has re-rendered
				setTimeout(() => {
					if (updatedJob) {
						setSelectedJob(updatedJob);
						const jobCard = document.getElementById(`job-card-${currentSelectedJobId}`);
						if (jobCard) {
							jobCard.scrollIntoView({behavior: 'smooth'});
						}
					}
				}, 300);

				setAppliedJobTrigger(false);
			} catch (error) {
				console.error('Error updating jobs:', error);
			}
		}
	};

	useEffect(() => {
		if (!jobsTriggered) {
			fetchJobs();
		}

		handleAppliedJobUpdate();

		if (isFilterOn) {
			setIsFilterOn(false);
			fetchJobs();
		}
	}, [currentUser, appliedJobTrigger]);

	async function fetchJobsOnPageChange(page) {
		setCurrentPage(page);
		const data = await getJobs(page); // Pass the page parameter directly instead of using currentPage
		setJobs(data?.results);
		setTotalJobs(data?.count);
		setTotalPages(data?.total_pages);
		setJobLoading(false);
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	async function fetchLikedJobs() {
		if (!currentUser.id) return;

		const data = await getLikedJobs();
		setLikedJobs(data);
		const newLocalLikedJobs = [];

		data.forEach((job) => {
			job?.likes?.forEach((like) => {
				if (like === currentUser.id) {
					newLocalLikedJobs.push({userId: currentUser.id, jobId: job.id});
				}
			});
		});

		setLocalLikedJobs(newLocalLikedJobs);
	}

	useEffect(() => {
		if (currentUser.id) {
			fetchLikedJobs();
		}
	}, [retrigger, currentUser]);

	const fetchCountryCode = async () => {
		const code = await getCountryCode();
		setCountryCode(code);
	};
	const setH1Text = () => {
		if (countryCode === COUNTRY_CODE.PHILIPPINES) {
			return 'Find remote jobs with employers worldwide';
		} else {
			return 'Hire talent from the Philippines direct';
		}
	};

	useEffect(() => {
		fetchCountryCode();
	}, []);

	function selectJob(job) {
		setSelectedJob(job);
		setModalShow1(true);
	}

	function selectJob2(job) {
		setSelectedJob2(job);
		setModalShow2(true);
	}

	function authenticatedButtons(jobSelection) {
		if (currentUser.id === jobSelection.created_by) {
			return;
		} else {
			const isJobLiked = localLikedJobs.find((job) => job.jobId === jobSelection.id && job.userId === currentUser.id);
			return isJobLiked ? (
				<Tooltip title="Saved">
					<Button icon={<Bookmark style={{color: permColors.charcoal, fontSize: '20px'}} />} onClick={() => heartClicked(jobSelection.id)} />
				</Tooltip>
			) : (
				<Tooltip title="Save job">
					<Button icon={<BookmarkBorder style={{color: permColors.charcoal, fontSize: '20px'}} />} onClick={() => heartClicked(jobSelection.id)} />
				</Tooltip>
			);
		}
	}

	const handleChange = (value) => {
		setActiveTab(value); // Update the tab key
		setSelected(value === 'List');

		if (value === 'List') {
			setSelectedJob2(likedJobs[0]);
		}
	};

	async function likeJob(id) {
		await likeJobApi(id);
		setRetrigger(!retrigger);
	}

	function closePopup() {
		setPopup(false);
	}

	function heartClicked(id) {
		if (!userExists(currentUser)) {
			setMessage('Please login to like a job');
			setGoogleModal(true);
			return;
		}
		const isJobLiked = localLikedJobs.find((job) => job.jobId === id && job.userId === currentUser.id);
		if (isJobLiked) {
			let filtered = localLikedJobs.filter((job) => job.jobId !== id);
			setLocalLikedJobs(filtered);
			if (!selected) {
				setSelectedJob2({});
			}
			if (selectedJob2.id === id) {
				setSelectedJob2({});
			}
			setSnackbarUnliked();
		} else {
			let newLikedJob = {
				userId: currentUser.id,
				jobId: id,
			};
			setLocalLikedJobs([...localLikedJobs, newLikedJob]);
			setSnackbarLiked();
		}
		likeJob(id);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	const setSnackbarLiked = () => {
		setSnackbarMessage('Job saved');
		setSnackbarSeverity('success');
		setSnackbarOpen(true);
	};

	const setSnackbarUnliked = () => {
		setSnackbarMessage('Job unsaved');
		setSnackbarSeverity('info');
		setSnackbarOpen(true);
	};

	useEffect(() => {
		console.log('selectedJob2: ');
	}, [selectedJob2]);

	return (
		<div className="landingPageContainer">
			<Helmet>
				<title>Permworks</title>
				<meta name="description" content="Explore new job opportunities on Permworks. Start your next career move with us." />
				<meta name="google-site-verification" content="2kKe4Wd2lwP2FcOIKXUuMI7dB-7bSF0tHDWZDzoLpew" />
			</Helmet>
			<Navbar backgroundColor={permColors.white} />
			<div className="landingPage">
				<div className="w-full bg-permFun flex justify-center p-4">
					<Card className="w-[1240px] max-w-full  p-3 text-left" key="r">
						<h1 key="b" className="text-2xl md:text-3xl font-semibold" style={{color: permColors.darkCharcoal}}>
							{setH1Text()}
						</h1>
						<h2 key="a" className="text-base md:text-base font-thin my-1 mt-2" style={{color: permColors.charcoal}}>
							Search for a company or role that suits you
						</h2>
						<div key="t" className="searchContainer">
							<AutoComplete
								className="mt-2 mb-2 w-full rounded-2xl bg-white"
								options={searchHistory?.map((search) => ({
									value: search,
									label: (
										<div className="flex justify-between items-center">
											<span>{search}</span>
											<CloseCircleOutlined
												onClick={(e) => {
													e.stopPropagation();
													clearSearchItem(search);
													setSearchHistory(searchHistory.filter((item) => item !== search));
												}}
											/>
										</div>
									),
								}))}
								placeholder={
									<div className="flex items-center gap-2 text-base">
										<SearchOutlined /> Search job title
									</div>
								}
								filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
								onSelect={onSearch}
								onSearch={(value) => setSearch(value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										onSearch(e.target.value);
									}
								}}
							/>
						</div>
						<p key="d" className="text-sm md:mt-1 mt-2" style={{color: permColors.charcoal}}>
							Are you an Employer?{' '}
							<Button type="link" className="pl-0" onClick={() => navigate(ROUTES.POST_JOB, {state: {from: 'employerLink'}})}>
								Post a job and we'll take care of the rest.
							</Button>
						</p>
					</Card>
				</div>
				<div key="e" className="secondContainer ">
					<div className="title-container" key="z">
						<Tabs
							className="p-3 pb-1 w-full max-w-[1260px] mx-auto"
							activeKey={activeTab} // Add this line
							size="large"
							onChange={handleChange}
							items={[
								{
									label: (
										<span className="flex items-center gap-2">
											<UnorderedListOutlined style={{fontSize: '18px'}} />
											Jobs feed
										</span>
									),
									key: 'List',
								},
								{
									label: (
										<span className="flex items-center gap-2">
											<BookmarkBorder style={{fontSize: '18px'}} />
											Saved jobs
										</span>
									),
									key: 'Kanban',
								},
							]}
						/>
					</div>
					{selected && (
						<div className="feedContainer max-w-[1260px] mx-auto" key="g">
							{!jobLoading ? (
								<div className="allFeeds lg:inline-block" key="l">
									{jobs?.length > 0 &&
										jobs?.map((job) => (
											<JobCard
												id={`job-card-${job.id}`}
												key={job.id}
												job={job}
												isSelected={job.id === selectedJob.id}
												onJobSelect={selectJob}
												authenticatedButtons={authenticatedButtons}
											/>
										))}
								</div>
							) : (
								<Spin className="mx-auto my-10" size="large" />
							)}

							{selectedJob?.title && !smallMatches && <SelectedJobCard job={selectedJob} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />}
						</div>
					)}

					{selectedJob?.title && smallMatches && (
						<JobModal job={selectedJob} show={modalShow1} onHide={() => setModalShow1(false)} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />
					)}

					{!selected && (
						<div className="feedContainer max-w-[1260px] mx-auto">
							<div className="allFeeds lg:inline-block">
								{likedJobs?.length > 0 ? (
									likedJobs?.map((job) => (
										<JobCard key={job.id} job={job} isSelected={job.id === selectedJob2.id} onJobSelect={selectJob2} authenticatedButtons={authenticatedButtons} />
									))
								) : (
									<Empty
										description={
											<span>
												No saved jobs yet
												<br />
												<Button className="ml-2 mt-2" onClick={() => handleChange('List')}>
													Browse jobs
												</Button>
											</span>
										}
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										className="my-8"
									/>
								)}
							</div>

							{selectedJob2?.title && !smallMatches && <SelectedJobCard job={selectedJob2} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />}

							{selectedJob2?.title && smallMatches && (
								<JobModal job={selectedJob2} show={modalShow2} onHide={() => setModalShow2(false)} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />
							)}
						</div>
					)}
				</div>
			</div>
			{totalJobs > 20 && selected && (
				<div className="pages mx-auto my-3">
					<CustomPagination disabled={jobLoading} count={totalPages} page={currentPage} onChange={(event, value) => fetchJobsOnPageChange(value)} />
				</div>
			)}
			<LoginMessage message={message} showPopup={popup} closePopup={closePopup} />
			<GoogleModal
				openGoogle={googleModal}
				dialogTitle="Login to Apply"
				dialogContent="You need to login to like to this job."
				button2="Login"
				closeGoogle={() => setGoogleModal(false)}
				button2function={() => navigate('/authenticate')}
				button1="Cancel"
				button1function={() => setGoogleModal(false)}
			/>
			<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
				<Alert onClose={handleClose} severity={snackbarSeverity} sx={{width: '100%'}} variant="filled">
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<Footer backgroundColor={permColors.backgroundGrey} />
		</div>
	);
}
