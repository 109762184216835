import React, {useEffect, useState, useContext} from 'react';
import './DashboardComponent.scss';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {UserContext} from '../../utils/UserContext';
import AddPaymentMethodForm from '../Stripe/AddPaymentMethodForm';
import {getCurrentUserPaymentMethodApi} from '../../api/stripeApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import QueueAnim from 'rc-queue-anim';
import {cardDetailsAtom} from '../../utils/atoms/dashboardAtoms';
import {useAtom} from 'jotai';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST_KEY); // Replace this with your Stripe publishable key

export default function Payments({setPaymentConfirmed, headerVisible = true, noPadding = false}) {
	const [currentUser] = useContext(UserContext);
	const [cardDetails, setCardDetails] = useAtom(cardDetailsAtom);
	const [loading, setLoading] = useState(false);
	const [retriggerGetPaymentMethod, setRetriggerGetPaymentMethod] = useState(false);
	async function getPaymentMethod() {
		setLoading(true);
		const res = await getCurrentUserPaymentMethodApi();
		setCardDetails(res);
		setLoading(false);
		if (setPaymentConfirmed) {
			if (res === false || res === null) {
				setPaymentConfirmed(false);
			}
			if (res !== false && res !== null) {
				setPaymentConfirmed(true);
			}
		}
	}
	useEffect(() => {
		getPaymentMethod();
	}, [retriggerGetPaymentMethod]);

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			{headerVisible && (
				<div className="header">
					<h2>Payment method</h2>
				</div>
			)}

			<div className={'outerContainer shadow-sm' + (noPadding ? ' px-0 mx-1 mt-1 mb-3 rounded-md' : '')}>
				<div className="innerContainer">
					<div className="cardInnerContainer">
						<QueueAnim delay={100} interval={200}>
							{cardDetails ? (
								<div className="credit-card">
									<div className="credit-card__header">
										<span className="credit-card__bank-name">Stripe Payment</span>
									</div>

									<div className="credit-card__body">
										<div className="credit-card__chip" />
										<div className="credit-card__number">**** **** **** {cardDetails.last4}</div>
										<div className="credit-card__details">
											<div className="credit-card__cardholder">
												<label>Cardholder</label>
												<span>
													{currentUser?.profile?.first_name} {currentUser?.profile?.last_name}
												</span>
											</div>
											<div className="credit-card__expiry">
												<label>Expiry</label>
												<span>
													{cardDetails.exp_month}/{cardDetails.exp_year}
												</span>
											</div>
										</div>
									</div>
								</div>
							) : (
								<p>No card information available.</p>
							)}
							<Elements stripe={stripePromise}>
								<AddPaymentMethodForm cardDetails={cardDetails} retriggerGetPaymentMethod={retriggerGetPaymentMethod} setRetriggerGetPaymentMethod={setRetriggerGetPaymentMethod} />
							</Elements>
						</QueueAnim>
					</div>
				</div>
			</div>
		</div>
	);
}
