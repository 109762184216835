import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext} from '../UserContext';

const AuthenticatedCheck = ({children}) => {
	const [currentUser] = useContext(UserContext);

	return currentUser?.profile ? children : <Navigate to="/authenticate" />;
};

export default AuthenticatedCheck;
