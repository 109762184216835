import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './Popups.scss';
import {sendNewMessageApi} from '../../api/messagingApi';
import {Modal, Input, Button, Spin, Alert} from 'antd';
import {ROUTES} from '../../utils/ROUTES';
import {useAtom} from 'jotai';
import {selectedAtom} from '../../utils/atoms';
import {conversationsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';

const {TextArea} = Input;

export default function MessagePopup(props) {
	const {showPopup, message, closePopup, link, linkName, title, textArea, buttonText, disabledButtonText, buttonFunction, user} = props;
	const [textAreaValue, setTextAreaValue] = useState('');
	const [responseMessage, setResponseMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useAtom(selectedAtom);
	const [conversationsTriggered, setConversationsTriggered] = useAtom(conversationsTriggeredAtom);
	const navigate = useNavigate();

	const handleTextAreaChange = (e) => {
		setTextAreaValue(e.target.value);
	};
	if (!showPopup) {
		return null;
	}

	const handleLinkClick = () => {
		setSelected('inbox');
		navigate(ROUTES.DASHBOARD);
	};

	const submit = async () => {
		setLoading(true);
		const res = await sendNewMessageApi(textAreaValue, user);
		if (res === true) {
			setResponseMessage(
				<span>
					Message sent successfully. To view your message, see your{' '}
					<Button className="p-0 m-0" type="link" onClick={handleLinkClick}>
						inbox
					</Button>
					.
				</span>
			);
			setDisabledButton(true);
			setLoading(false);
			setTextAreaValue('');
			setConversationsTriggered(false);
		}
		console.log(res);
		setLoading(false);
	};

	return (
		<Modal open={showPopup} onCancel={closePopup} title={title} footer={null}>
			<p>{message}</p>
			{textArea && (
				<div className="mb-2">
					<p className="mb-2">Type your message below</p>
					<TextArea rows={3} onChange={handleTextAreaChange} value={textAreaValue} />
				</div>
			)}
			{responseMessage && <Alert message={responseMessage} type="success" showIcon banner className="mb-2 rounded-md" />}

			{buttonText && (
				<Button type="primary" block onClick={submit} disabled={disabledButton || loading} loading={loading}>
					{disabledButton ? disabledButtonText : buttonText}
				</Button>
			)}

			{link && <Link to={link}></Link>}
		</Modal>
	);
}
