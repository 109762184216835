import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './PricingPage.scss';
import {Helmet} from 'react-helmet';

import {EXTERNAL_ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';
import {eorCard, otherSpecializationCards, pricingCard} from './pricing-helpers';
import {pricingData, subscriptionOptionsPricing} from '../../utils/arrayData/PricingData';

export default function PricingPage() {
	return (
		<div>
			<Helmet>
				<title>Pricing | Permworks</title>
				<meta name="description" content="Learn about our pricing. Discover cost-effective solutions for your hiring needs on Permworks." />
			</Helmet>
			<Navbar2 />
			<div className="pricing-container mb-20 mt-10">
				<h1 className="text-3xl md:text-4xl mb-2 text-darkCharcoal">Plans and Pricing</h1>
				<h2 className="text-xl md:text-xl mb-2 text-darkCharcoal">Our Job Posting Solutions</h2>
				<p className="tag-line text-base md:text-base mb-8" style={{color: permColors.charcoal}}>
					At Permworks, we offer flexible subscription plans to match every recruitment need.
				</p>
				{pricingCard(subscriptionOptionsPricing)}
				{/* {eorCard()} */}

				<h3 style={{color: permColors.charcoal}} className="font-semibold mb-3 mt-32">
					Our Other Specializations
				</h3>
				{otherSpecializationCards(pricingData)}
			</div>
			<Footer backgroundColor={permColors.backgroundGrey} />
		</div>
	);
}
