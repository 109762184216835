import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './Faq.scss';
import {Helmet} from 'react-helmet';
import {employersQuestions, jobSeekerQuestions} from '../../utils/arrayData/faqData';
import {Button, Card, Collapse} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../utils/ROUTES';

export default function Faq() {
	const navigate = useNavigate();
	return (
		<div>
			<Helmet>
				<title>FAQ | Permworks</title>
				<meta name="description" content="Frequently Asked Questions. Find answers to common questions about our platform, hiring process, and more." />
			</Helmet>
			<Navbar2 />
			<div className="faq">
				<h1 className="text-2xl font-bold text-deepBlue mb-4">Frequently asked questions</h1>
				<p className="text-sm text-charcoal mb-6">
					Our Frequently Asked Questions section is designed to provide comprehensive responses to the queries we encounter most often from our users.
				</p>
				<h3 className="text-charcoal text-lg font-semibold mb-2">Job Seeker Questions</h3>
				<Collapse className="accordian">
					{jobSeekerQuestions.map(({question, answer}, index) => (
						<Collapse.Panel header={question} key={index} className="accordian-item">
							{answer}
						</Collapse.Panel>
					))}
				</Collapse>
				<br />
				<h3 className="text-charcoal text-lg font-semibold mb-2">Employer Questions</h3>
				<Collapse className="accordian">
					{employersQuestions.map(({question, answer}, index) => (
						<Collapse.Panel header={question} key={index} className="accordian-item">
							{answer}
						</Collapse.Panel>
					))}
				</Collapse>
				<div className="card-container ">
					<Card className="card flex justify-between flex-col gap-2">
						<h3 className="text-lg font-bold mb-2">Need more help?</h3>
						<p className="text-sm text-charcoal mb-2">Get in touch and we will do our best to answer your queries</p>
						<Button type="default" onClick={() => navigate(ROUTES.CONTACT_US)} className="w-full">
							Get in touch
						</Button>
					</Card>
					<Card className="card flex justify-between flex-col gap-2 shadow-border-no-background">
						<h3 className="text-lg font-bold mb-2">What we offer</h3>
						<p className="text-sm text-charcoal mb-2">Learn more about our services and how we can help you</p>
						<Button type="default" onClick={() => navigate(ROUTES.PRICING)} className="w-full">
							View our services
						</Button>
					</Card>
				</div>
			</div>
			<Footer />
		</div>
	);
}
