import {atom} from 'jotai';

export const billingHistoryAtom = atom<any[]>([]);
export const billingHistoryTriggeredAtom = atom<boolean>(false);

export const jobsAtom = atom<any[]>([]);
export const jobsTriggeredAtom = atom<boolean>(false);

export const closedJobsAtom = atom<any[]>([]);
export const closedJobsTriggeredAtom = atom<boolean>(false);

export const conversationsAtom = atom<any[]>([]);
export const conversationsTriggeredAtom = atom<boolean>(false);
export const displayedConversationsAtom = atom<any[]>([]);

export const cardDetailsAtom = atom<any>(null);
export const paymentMethodTriggeredAtom = atom<boolean>(false);
