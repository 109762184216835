import React, {useEffect, useState} from 'react';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions} from '../../utils/arrayData/PortfolioArrays';
import {DeleteOutlined, InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {editJobApi, getJobById} from '../../api/jobsApi';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Radio, Steps, Form, Input, Select, Button, List, Alert, Space, Typography, Layout, Modal, Tooltip, Card, notification} from 'antd';
import {useNavigate} from 'react-router-dom';
import {permColors} from '../../utils/colors';
import Loading from '../../components/Loaders/Loading';
import {PostAdd} from '@mui/icons-material';
import {Alert as MuiAlert} from '@mui/material';
import {daysOfWeek, timeSlots, timezoneData} from '../../utils/arrayData/timezone';
import {philippineCities} from '../../utils/arrayData/philippinesCities';

const {Title, Text} = Typography;
const {Content} = Layout;

function EditJobForm({jobId}) {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [loading, setLoading] = useState(true);
	const [fetchedJob, setFetchedJob] = useState(null);

	// Basic job details
	const [jobTitle, setJobTitle] = useState('');
	const [description, setDescription] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [country, setCountry] = useState('');
	const [category, setCategory] = useState('');
	const [roleLocationType, setRoleLocationType] = useState('');
	const [roleLocation, setRoleLocation] = useState('');

	// Job type and engagement
	const [jobType, setJobType] = useState('');
	const [engagementType, setEngagementType] = useState('');

	// Payment details
	const [paymentRateType, setPaymentRateType] = useState('');
	const [paymentRateFrom, setPaymentRateFrom] = useState(null);
	const [paymentRateTo, setPaymentRateTo] = useState(null);
	const [paymentRateCurrency, setPaymentRateCurrency] = useState('');
	const [showSalaryOnJob, setShowSalaryOnJob] = useState(true);

	// Schedule details
	const [workScheduleType, setWorkScheduleType] = useState('');
	const [workScheduleTimezone, setWorkScheduleTimezone] = useState('');
	const [workScheduleDays, setWorkScheduleDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
	const [workScheduleHoursStart, setWorkScheduleHoursStart] = useState('09:00');
	const [workScheduleHoursEnd, setWorkScheduleHoursEnd] = useState('18:00');
	const [workScheduleFlexibleNotes, setWorkScheduleFlexibleNotes] = useState('');
	const [shifts, setShifts] = useState([]);
	const [currentShift, setCurrentShift] = useState({
		day_of_week: null,
		start_time: null,
		end_time: null,
	});

	// Equipment and benefits
	const [equipmentAllocation, setEquipmentAllocation] = useState('');
	const [provideHMO, setProvideHMO] = useState(false);

	// Questions
	const [questions, setQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [coverLetterRequired] = useState(true);

	// UI state
	const [errorMsg, setErrorMsg] = useState('');
	const [currentStep, setCurrentStep] = useState(0);
	const [isApplying, setIsApplying] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [completedSteps, setCompletedSteps] = useState(new Set());

	const categories = categoryOptions;
	const countriesList = countries;

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		console.log(shifts);
	}, [shifts]);

	function orderDaysOfWeekByDay(days) {
		const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
		return daysOfWeek.filter((day) => days?.map((d) => d?.toLowerCase())?.includes(day?.toLowerCase()) || false);
	}

	const validateInitialData = async () => {
		const stepFields = {
			0: ['jobTitle', 'companyName', 'country', 'category', 'roleLocationType', 'roleLocation'],
			1: ['description'],
			2: ['jobType', 'engagementType'],
			3: ['paymentRateType', 'paymentRateFrom', 'paymentRateTo', 'paymentRateCurrency', 'showSalaryOnJob'],
			4: ['workScheduleType', 'workScheduleTimezone', 'workScheduleDays', 'workScheduleHoursStart', 'workScheduleHoursEnd'],
			5: ['equipmentAllocation', 'provideHMO'],
			6: [], // Questions step has no required fields
		};

		// Validate each step
		for (let stepIndex = 0; stepIndex < Object.keys(stepFields).length; stepIndex++) {
			try {
				await form.validateFields(stepFields[stepIndex]);
				setCompletedSteps((prev) => new Set([...prev, stepIndex]));
			} catch (error) {
				// Stop at first invalid step
				break;
			}
		}
	};

	const fetchJob = async () => {
		try {
			const res = await getJobById(jobId);
			setFetchedJob(res);

			// Populate form fields with existing data
			setJobTitle(res.title);
			setDescription(res.description);
			setCompanyName(res.company_name);
			setCountry(res.country);
			setCategory(res.category);
			setRoleLocationType(res.role_location_type || 'remote');
			setRoleLocation(res.role_location);
			setJobType(res.job_type);
			setEngagementType(res.engagement_type);

			// Payment details
			setPaymentRateType(res.payment_rate_type || 'monthly');
			setPaymentRateFrom(res.payment_rate_from);
			setPaymentRateTo(res.payment_rate_to);
			setPaymentRateCurrency(res.payment_rate_currency || 'PHP');
			setShowSalaryOnJob(res.show_salary_on_job);

			// Schedule details
			setWorkScheduleType(res.work_schedule_type || 'fixed');
			setWorkScheduleTimezone(res.work_schedule_timezone);
			setWorkScheduleDays(res.work_schedule_days?.split(',') || ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
			setWorkScheduleHoursStart(res.work_schedule_hours_start);
			setWorkScheduleHoursEnd(res.work_schedule_hours_end);
			setWorkScheduleFlexibleNotes(res.work_schedule_flexible_notes);
			setShifts(res.shifts || []);

			// Equipment and benefits
			setEquipmentAllocation(res.equipment_allocation);
			setProvideHMO(res.provide_hmo);

			// Questions
			setQuestions(res.questions || []);

			form.setFieldsValue({
				jobTitle: res.title,
				companyName: res.company_name,
				description: res.description,
				country: res.country,
				category: res.category,
				roleLocationType: res.role_location_type || 'remote',
				roleLocation: res.role_location,
				jobType: res.job_type,
				engagementType: res.engagement_type,
				paymentRateType: res.payment_rate_type || 'monthly',
				paymentRateFrom: res.payment_rate_from,
				paymentRateTo: res.payment_rate_to,
				paymentRateCurrency: res.payment_rate_currency || 'PHP',
				showSalaryOnJob: res.show_salary_on_job,
				workScheduleType: res.work_schedule_type || 'fixed',
				workScheduleTimezone: res.work_schedule_timezone,
				workScheduleDays: res.work_schedule_days?.split(',') || ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
				workScheduleHoursStart: res.work_schedule_hours_start,
				workScheduleHoursEnd: res.work_schedule_hours_end,
				workScheduleFlexibleNotes: res.work_schedule_flexible_notes,
				equipmentAllocation: res.equipment_allocation,
				provideHMO: res.provide_HMO,
			});
			validateInitialData();

			setLoading(false);
		} catch (error) {
			console.error('Error fetching job:', error);
			setLoading(false);
			setErrorMsg('Failed to load job details');
		}
	};

	useEffect(() => {
		fetchJob();
	}, [jobId]);

	const validateStep = async () => {
		try {
			const currentStepFields = {
				0: ['jobTitle', 'companyName', 'country', 'category', 'roleLocationType', 'roleLocation'],
				1: ['description'],
				2: ['jobType', 'engagementType'],
				3: ['paymentRateType', 'paymentRateFrom', 'paymentRateTo', 'paymentRateCurrency', 'showSalaryOnJob'],
				4: ['workScheduleType', 'workScheduleTimezone', 'workScheduleDays', 'workScheduleHoursStart', 'workScheduleHoursEnd'],
				5: ['equipmentAllocation', 'provideHMO'],
				6: [], // Questions step has no required fields
				7: [], // Review step has no required fields
			};
			await form.validateFields(currentStepFields[currentStep]);
			setCompletedSteps((prev) => new Set([...prev, currentStep]));
			return true;
		} catch (error) {
			return false;
		}
	};

	const isStepAccessible = (stepIndex) => {
		// For the review step, require all previous steps to be completed
		if (stepIndex === steps.length - 1) {
			return Array.from({length: stepIndex}, (_, i) => i).every((i) => completedSteps.has(i));
		}

		// For other steps, require all previous steps to be completed
		// and current step to be either completed or adjacent
		return Array.from({length: stepIndex}, (_, i) => i).every((i) => completedSteps.has(i)) && (completedSteps.has(stepIndex) || stepIndex === currentStep + 1);
	};

	// Add this function to check if all required fields are filled
	const areAllRequiredFieldsFilled = async () => {
		try {
			// Get all field names that need validation
			const allFields = [
				// Step 0
				'jobTitle',
				'companyName',
				'country',
				'category',
				'roleLocationType',
				'roleLocation',
				// Step 1
				'description',
				// Step 2
				'jobType',
				'engagementType',
				'workScheduleType',
				'workScheduleTimezone',
				// Step 3
				'paymentRateType',
				'paymentRateFrom',
				'paymentRateTo',
				'paymentRateCurrency',
				'showSalaryOnJob',
				// Step 4
				'equipmentAllocation',
				'provideHMO',
			];

			await form.validateFields(allFields);
			return true;
		} catch (error) {
			return false;
		}
	};

	const handleNext = async () => {
		// For the second-to-last step, check all fields before allowing access to review
		if (currentStep === steps.length - 2) {
			const isValid = await areAllRequiredFieldsFilled();
			if (!isValid) {
				notification.error({
					message: 'Incomplete Information',
					description: 'Please fill in all required fields before proceeding to review.',
					duration: 5,
				});
				return;
			}
		}

		if (await validateStep()) {
			setCurrentStep(currentStep + 1);
		}
	};

	const handleBack = async () => {
		// Remove the current step from completedSteps since we're leaving it
		if (!(await validateStep())) {
			setCompletedSteps((prev) => {
				const newSet = new Set(prev);
				newSet.delete(currentStep);
				return newSet;
			});
		}
		setCurrentStep(currentStep - 1);
	};

	const addQuestion = () => {
		if (currentQuestion.trim()) {
			setQuestions([...questions, {question: currentQuestion}]);
			setCurrentQuestion('');
		}
	};

	const removeQuestion = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
	};

	const showPublishConfirm = () => {
		setIsModalVisible(true);
	};

	function tooltip(title, description) {
		return (
			<div className="flex items-center gap-2">
				<span className="font-semibold">{title}</span>
				<Tooltip className="cursor-pointer" title={description}>
					<InfoCircleOutlined style={{fontSize: '14px', background: permColors.white}} className="p-1 rounded-lg" />
				</Tooltip>
			</div>
		);
	}

	useEffect(() => {
		console.log(paymentRateFrom, paymentRateTo);
	}, [paymentRateFrom, paymentRateTo]);

	const handlePublish = async () => {
		try {
			setIsApplying(true);
			setIsModalVisible(false);
			const orderedDays = orderDaysOfWeekByDay(workScheduleDays);

			const res = await editJobApi(
				jobId,
				jobTitle,
				description,
				companyName,
				country,
				jobType,
				category,
				engagementType,
				roleLocationType,
				roleLocation,
				paymentRateType,
				paymentRateFrom,
				paymentRateTo,
				paymentRateCurrency,
				showSalaryOnJob,
				workScheduleType,
				workScheduleTimezone,
				orderedDays.join(','),
				workScheduleHoursStart,
				workScheduleHoursEnd,
				workScheduleFlexibleNotes,
				shifts,
				equipmentAllocation,
				provideHMO,
				questions,
				coverLetterRequired
			);

			setIsApplying(false);

			if (res) {
				navigate(`/jobpage/${res.id}`);
				notification.success({
					message: 'Job updated successfully',
					description: 'Your job has been updated successfully',
					duration: 5,
				});
			}
			if (res === false) {
				notification.error({
					message: 'Error',
					description: 'Please fill in all required fields',
					duration: 5,
				});
			}
		} catch (error) {
			setErrorMsg('Please fill in all required fields');
			setIsApplying(false);
			notification.error({
				message: 'Error',
				description: 'Please fill in all required fields',
				duration: 5,
			});
		}
	};

	const steps = [
		{
			title: 'Job Details',
			content: (
				<Form.Item>
					<Space direction="vertical" size="large" style={{width: '100%'}}>
						<Form.Item name="jobTitle" label="Title" rules={[{required: true, message: 'Please enter a job title'}]}>
							<Input placeholder="Virtual assistant" onChange={(e) => setJobTitle(e.target.value)} />
						</Form.Item>

						<Form.Item name="companyName" label="Company name" rules={[{required: true, message: 'Please enter a company name'}]}>
							<Input placeholder="Permworks" onChange={(e) => setCompanyName(e.target.value)} />
						</Form.Item>

						<Form.Item name="country" label="Company location" rules={[{required: true, message: 'Please select a country'}]}>
							<Select placeholder="Select country" onChange={(value) => setCountry(value)} showSearch>
								{countriesList.map((country, index) => (
									<Select.Option key={index} value={country} disabled={country === '----'}>
										{country}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item name="category" label="Category" rules={[{required: true, message: 'Please select a category'}]}>
							<Select placeholder="Select category" onChange={(value) => setCategory(value)}>
								{categories.map((category, index) => (
									<Select.Option key={index} value={category}>
										{category}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item name="roleLocationType" label="Role Location" rules={[{required: true, message: 'Please select a role location'}]}>
							<Radio.Group
								className="flex flex-col gap-1"
								onChange={(e) => {
									setRoleLocationType(e.target.value);
									if (e.target.value === 'remote') {
										setRoleLocation('');
										form.setFieldValue('roleLocation', '');
									}
								}}
								buttonStyle="solid">
								<Radio value="remote">Remote</Radio>
								<Radio value="hybrid">Hybrid</Radio>
								<Radio value="onsite">On-site</Radio>
							</Radio.Group>
						</Form.Item>

						{(roleLocationType === 'hybrid' || roleLocationType === 'onsite') && (
							<Form.Item name="roleLocation" rules={[{required: true, message: 'Please enter the work location'}]} label="Work Location">
								<Select placeholder="Select city" onChange={(value) => setRoleLocation(value)} options={philippineCities.map((city) => ({label: city, value: city}))} showSearch />
							</Form.Item>
						)}
					</Space>
				</Form.Item>
			),
		},
		{
			title: 'Description',
			content: (
				<Form.Item
					name="description"
					label="Job Description"
					rules={[
						{
							required: true,
							validator: (_, value) => {
								const cleanContent = description.replace(/<[^>]*>/g, '').trim();
								if (!cleanContent) {
									return Promise.reject('Please enter a job description');
								}
								return Promise.resolve();
							},
						},
					]}>
					<CKEditor
						editor={ClassicEditor}
						config={{
							toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote'],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setDescription(data);
							form.setFieldValue('description', data);
							form.validateFields(['description']);
						}}
						data={description}
						minHeight={500}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Hiring Details',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item name="jobType" label="Employment Type" rules={[{required: true, message: 'Please select an employment type'}]}>
						<Radio.Group onChange={(e) => setJobType(e.target.value)} buttonStyle="solid">
							<Radio value="Full-Time">Full-Time</Radio>
							<Radio value="Part-Time">Part-Time</Radio>
							<Radio value="Contract">Contract</Radio>
							<Radio value="Temporary">Temporary</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item
						className="mb-1"
						name="workScheduleType"
						label={tooltip('Work Schedule Type', 'Select the type of work schedule for this position')}
						rules={[{required: true, message: 'Please select a work schedule type'}]}>
						<Radio.Group
							onChange={(e) => {
								setWorkScheduleType(e.target.value);
								form.setFieldValue('workScheduleType', e.target.value);
							}}
							buttonStyle="solid">
							<Radio value="fixed">Fixed Schedule</Radio>
							<Radio value="shift-based">Shift-Based</Radio>
							<Radio value="flexible">Flexible Schedule</Radio>
							<Radio value="anytime">Anytime</Radio>
						</Radio.Group>
					</Form.Item>

					{workScheduleType && (
						<MuiAlert severity="info" className="mb-4">
							{workScheduleType === 'fixed'
								? 'Employees work the same hours each day, like 9 AM to 6 PM Monday-Friday.'
								: workScheduleType === 'flexible'
									? 'Employees can work different hours while meeting required hours per day/week.'
									: workScheduleType === 'shift-based'
										? 'Work is divided into shifts that can vary by time and day. Shifts may rotate between employees.'
										: 'Employees can work during any hours set by the employer.'}
						</MuiAlert>
					)}

					{workScheduleType && workScheduleType === 'fixed' && (
						<>
							<Form.Item name="workScheduleTimezone" label="Timezone" rules={[{required: true, message: 'Please select a timezone'}]}>
								<Select
									showSearch
									placeholder="Select timezone"
									options={timezoneData.map((timezone) => ({label: timezone.label, value: timezone.label}))}
									onChange={(value) => {
										setWorkScheduleTimezone(value);
										form.setFieldValue('workScheduleTimezone', value);
									}}
									filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								/>
							</Form.Item>

							{workScheduleType === 'fixed' && (
								<Form.Item name="workScheduleDays" label="Working Days" rules={[{required: true, message: 'Please select working days'}]}>
									<Select
										mode="multiple"
										placeholder="Select working days"
										onChange={(value) => {
											setWorkScheduleDays(value);
											form.setFieldValue('workScheduleDays', value);
										}}
										options={[
											{label: 'Monday', value: 'monday'},
											{label: 'Tuesday', value: 'tuesday'},
											{label: 'Wednesday', value: 'wednesday'},
											{label: 'Thursday', value: 'thursday'},
											{label: 'Friday', value: 'friday'},
											{label: 'Saturday', value: 'saturday'},
											{label: 'Sunday', value: 'sunday'},
										]}
									/>
								</Form.Item>
							)}
						</>
					)}

					{workScheduleType === 'fixed' && (
						<>
							<Form.Item
								name="workScheduleHours"
								label={`${workScheduleType === 'fixed' ? 'Working' : 'Core working'} hours`}
								rules={[{required: true, message: 'Please select working hours'}]}>
								<Space>
									<Select
										placeholder="Start time"
										value={workScheduleHoursStart}
										options={timeSlots.map((time) => ({label: time, value: time}))}
										onChange={(time) => {
											setWorkScheduleHoursStart(time);
											form.setFieldValue('workScheduleHoursStart', time);
										}}
										className="min-w-[120px]"
									/>
									<Select
										placeholder="End time"
										value={workScheduleHoursEnd}
										options={timeSlots.map((time) => ({label: time, value: time}))}
										onChange={(time) => {
											setWorkScheduleHoursEnd(time);
											form.setFieldValue('workScheduleHoursEnd', time);
										}}
										className="min-w-[120px]"
									/>
								</Space>
							</Form.Item>
						</>
					)}

					{/* {workScheduleType === 'flexible' && (
						<Form.Item name="workScheduleFlexibleNotes" label="Flexible working hours notes">
							<Input placeholder="Enter notes" onChange={(e) => setWorkScheduleFlexibleNotes(e.target.value)} />
						</Form.Item>
					)} */}

					{workScheduleType === 'shift-based' && (
						<Space direction="vertical" size="large" style={{width: '100%'}}>
							<Form.Item label="Add Shifts">
								<Space direction="vertical" style={{width: '100%'}}>
									<div className="flex md:flex-row flex-col gap-2 w-full">
										<Select
											placeholder="Day"
											options={daysOfWeek.map((day) => ({label: day, value: day}))}
											onChange={(day) => setCurrentShift((prev) => ({...prev, day_of_week: day}))}
											className="min-w-[120px]"
											value={currentShift.day_of_week}
										/>
										<Select
											placeholder="Start time"
											options={timeSlots.map((time) => ({label: time, value: time}))}
											onChange={(time) => setCurrentShift((prev) => ({...prev, start_time: time}))}
											value={currentShift.start_time}
										/>
										<Select
											placeholder="End time"
											options={timeSlots.map((time) => ({label: time, value: time}))}
											onChange={(time) => setCurrentShift((prev) => ({...prev, end_time: time}))}
											value={currentShift.end_time}
										/>

										<Button
											icon={<PlusOutlined />}
											className="min-h-[40px]"
											onClick={() => {
												if (currentShift.start_time && currentShift.end_time && currentShift.day_of_week) {
													setShifts([...shifts, {...currentShift}]);
													setCurrentShift({start_time: null, end_time: null, day_of_week: null});
												}
											}}>
											Add Shift
										</Button>
									</div>

									<List
										dataSource={shifts}
										renderItem={(shift, index) => (
											<List.Item
												actions={[
													<Button
														type="text"
														danger
														icon={<DeleteOutlined />}
														onClick={() => {
															const newShifts = [...shifts];
															newShifts.splice(index, 1);
															setShifts(newShifts);
														}}
													/>,
												]}>
												<Text>{`${shift.day_of_week} - ${shift.start_time} - ${shift.end_time}`}</Text>
											</List.Item>
										)}
									/>
								</Space>
							</Form.Item>
						</Space>
					)}

					<Form.Item name="engagementType" label="Engagement Type" rules={[{required: true, message: 'Please select an engagement type'}]}>
						<div className="flex md:flex-row flex-col gap-2">
							<Card
								className="flex-1 w-1/2"
								title={
									<div className="flex flex-row gap-2">
										<Radio value="Employee" checked={engagementType === 'employee'} />
										Employee Contract
									</div>
								}
								style={{
									width: '100%',
									cursor: 'pointer',
									border: engagementType === 'employee' ? '2px solid #1890ff' : '1px solid #d9d9d9',
								}}
								onClick={() => {
									setEngagementType('employee');
									form.setFieldValue('engagementType', 'employee');
								}}>
								<div className="text-gray-600 text-sm leading-relaxed mb-3 bg-blue-50 p-3 rounded-lg border border-blue-100">
									Applicable for <b>Philippines registered companies</b> or{' '}
									<b>
										foreign companies (US, UK, Canada, Australia, etc.) through an Employer of Record Service (hire under a 3rd party company without needing to set one up
										yourself)
									</b>
								</div>
								<p className="text-sm font-normal mb-1 underline">Typically includes:</p>
								<ul className="list-disc list-inside italic">
									<li>Vacation Leave</li>
									<li>Public Holiday Leave</li>
									<li>Government Mandated Benefits (SSS, PhilHealth, Pag-IBIG)</li>
									<li>13th Month</li>
									<li>Overtime loading (10pm - 6am PH)</li>
								</ul>
							</Card>
							<Card
								className="flex-1 w-1/2"
								title={
									<div className="flex flex-row gap-2">
										<Radio value="Contractor" checked={engagementType === 'contractor'} />
										Contractor Agreement
									</div>
								}
								style={{
									width: '100%',
									cursor: 'pointer',
									border: engagementType === 'contractor' ? '2px solid #1890ff' : '1px solid #d9d9d9',
								}}
								onClick={() => {
									setEngagementType('contractor');
									form.setFieldValue('engagementType', 'contractor');
								}}>
								<div className="text-gray-600 text-sm leading-relaxed mb-3 bg-blue-50 p-3 rounded-lg border border-blue-100">
									Applicable for <b>Philippines registered companies</b> or <b>Non-Philippines (US, UK, AU, etc) registered companies directly.</b>
								</div>
								<p className="text-sm font-normal mb-1 underline">Typically includes:</p>
								<ul className="list-disc list-inside italic">
									<li>No Vacation Leave</li>
									<li>No Public Holiday Leave</li>
									<li>No Government Mandated Benefits</li>
									<li>No 13th Month</li>
									<li>No nighttime loading</li>
								</ul>
							</Card>
						</div>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Salary',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item name="paymentRateType" label="Payment Rate Type" rules={[{required: true, message: 'Please select a payment rate type'}]}>
						<Radio.Group onChange={(e) => setPaymentRateType(e.target.value)} buttonStyle="solid">
							<Radio value="hourly">Hourly Rate</Radio>
							<Radio value="monthly">Monthly Salary</Radio>
							<Radio value="annual">Annual Salary</Radio>
						</Radio.Group>
					</Form.Item>

					<div className="grid grid-cols-1 md:grid-cols-8 gap-2">
						<Form.Item name="paymentRateCurrency" label="Currency" rules={[{required: true, message: 'Please select a currency'}]} className="col-span-2 md:col-span-4">
							<Select
								onChange={(value) => setPaymentRateCurrency(value)}
								options={[
									{label: 'PHP - Philippine Peso', value: 'PHP'},
									{label: 'USD - US Dollar', value: 'USD'},
									{label: 'CAD - Canadian Dollar', value: 'CAD'},
									{label: 'AUD - Australian Dollar', value: 'AUD'},
									{label: 'NZD - New Zealand Dollar', value: 'NZD'},
								]}
							/>
						</Form.Item>

						<Form.Item className="col-span-2" name="paymentRateFrom" label="Payment Rate From" rules={[{required: true, message: 'Please enter the minimum payment rate'}]}>
							<Input type="number" placeholder="Minimum pay" onChange={(e) => setPaymentRateFrom(e.target.value)} />
						</Form.Item>

						<Form.Item className="col-span-2" name="paymentRateTo" label="Payment Rate To" rules={[{required: true, message: 'Please enter the maximum payment rate'}]}>
							<Input type="number" placeholder="Maximum pay" onChange={(e) => setPaymentRateTo(e.target.value)} />
						</Form.Item>
					</div>

					<Form.Item name="showSalaryOnJob" label="Show Salary" rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setShowSalaryOnJob(e.target.value)}>
							<Radio value={true}>Show salary on job post</Radio>
							<Radio value={false}>Hide salary on job post</Radio>
						</Radio.Group>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Equipment & extras',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item name="equipmentAllocation" label="Equipment Allocation" rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setEquipmentAllocation(e.target.value)} className="flex flex-col gap-2">
							<Radio value="candidate to use their own laptop">Candidate to use their own laptop</Radio>
							<Radio value="company supplied laptop">Company supplied laptop</Radio>
							<Radio value="monthly allowance for equipment">Monthly allowance for equipment</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item name="provideHMO" label="Private health insurance (HMO)" rules={[{required: true, message: 'Please select an option'}]}>
						<Radio.Group onChange={(e) => setProvideHMO(e.target.value)} className="flex flex-col gap-2">
							<Radio value={true}>Company provided HMO</Radio>
							<Radio value={false}>Not provided</Radio>
						</Radio.Group>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Questions',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					{tooltip('Questions', 'Add questions for candidates to answer')}
					<Input.Group compact>
						<Input style={{width: 'calc(100% - 60px)'}} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} placeholder="Enter a question for candidates" />
						<Button type="primary" onClick={addQuestion}>
							Add
						</Button>
					</Input.Group>

					<List
						dataSource={questions}
						renderItem={(item, index) => (
							<List.Item actions={[<Button type="text" danger icon={<DeleteOutlined />} onClick={() => removeQuestion(index)} />]}>
								<Text>{item.question}</Text>
							</List.Item>
						)}
					/>
				</Space>
			),
		},
		{
			title: 'Review',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<List
						itemLayout="horizontal"
						dataSource={[
							{label: 'Job Title', value: jobTitle},
							{label: 'Company Name', value: companyName},
							{label: 'Country', value: country},
							{label: 'Category', value: category},
							{label: 'Role Location Type', value: roleLocationType},
							...(roleLocationType !== 'remote' ? [{label: 'Work Location', value: roleLocation}] : []),
							{label: 'Job Type', value: jobType},
							{label: 'Engagement Type', value: engagementType === 'employee' ? 'Employee Contract' : 'Contractor Agreement'},
							{label: 'Payment Rate Type', value: paymentRateType === 'hourly' ? 'Hourly Rate' : paymentRateType === 'monthly' ? 'Monthly Salary' : 'Annual Salary'},
							{label: 'Payment Rate Currency', value: paymentRateCurrency},
							{label: 'Payment Rate', value: `${paymentRateFrom} - ${paymentRateTo} ${paymentRateCurrency}`},
							{label: 'Show Salary on Job Post', value: showSalaryOnJob ? 'Yes' : 'No'},
							{
								label: 'Work Schedule Type',
								value:
									workScheduleType === 'fixed'
										? 'Fixed Schedule'
										: workScheduleType === 'flexible'
											? 'Flexible Schedule'
											: workScheduleType === 'shift-based'
												? 'Shift-Based'
												: 'Anytime',
							},
							...(workScheduleType && workScheduleType !== 'anytime' ? [{label: 'Timezone', value: workScheduleTimezone}] : []),
							...(workScheduleType !== 'anytime' && workScheduleType !== 'shift-based' ? [{label: 'Working Days', value: orderDaysOfWeekByDay(workScheduleDays).join(', ')}] : []),
							...(workScheduleType === 'fixed' || workScheduleType === 'flexible'
								? [{label: `${workScheduleType === 'fixed' ? 'Working' : 'Core Working'} Hours`, value: `${workScheduleHoursStart} - ${workScheduleHoursEnd}`}]
								: []),
							...(workScheduleType === 'flexible' && workScheduleFlexibleNotes ? [{label: 'Flexible Schedule Notes', value: workScheduleFlexibleNotes}] : []),
							{label: 'Equipment Allocation', value: equipmentAllocation},
							{label: 'Private Health Insurance (HMO)', value: provideHMO ? 'Company Provided' : 'Not Provided'},
						]}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta title={item.label} description={item.value || 'Not specified'} />
							</List.Item>
						)}
					/>

					{questions.length > 0 && (
						<>
							<Title level={5}>Questions</Title>
							<List
								dataSource={questions}
								renderItem={(item, index) => (
									<List.Item>
										<Text strong>{`Question ${index + 1}: `}</Text>
										<Text>{item.question}</Text>
									</List.Item>
								)}
							/>
						</>
					)}
					{shifts.length > 0 && (
						<>
							<Title level={5}>Shifts</Title>
							<List
								dataSource={shifts}
								renderItem={(item, index) => (
									<List.Item>
										<Text strong>{`Shift ${index + 1}: `}</Text>
										<Text>{`${item.day_of_week} - ${item.start_time} - ${item.end_time}`}</Text>
									</List.Item>
								)}
							/>
						</>
					)}
				</Space>
			),
		},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="flex justify-center p-8">
			<div className="w-full max-w-6xl">
				<h1 className="text-left text-2xl sm:text-3xl m-0 font-semibold" style={{color: permColors.darkCharcoal}}>
					Edit job
				</h1>
				<h2 className="text-left text-base m-0 font-normal mb-3" style={{color: permColors.darkCharcoal}}>
					Edit your job posting for {jobTitle ? <span className="font-semibold capitalize">{jobTitle}</span> : 'your job'}
				</h2>
				<Form form={form} layout="vertical" className="shadow-border rounded-lg p-2 bg-[#fafafa]">
					<div className="flex flex-col md:flex-row gap-6">
						<div className="w-full md:w-1/4 p-3">
							<div className="p-2 flex items-center gap-2 rounded-lg mb-3" style={{color: permColors.charcoal}}>
								<PostAdd style={{fontSize: '20px'}} />
								<h3 className="text-left text-base m-0 font-normal">Edit job form</h3>
							</div>

							<Steps
								direction={windowWidth <= 768 ? 'horizontal' : 'vertical'}
								current={currentStep}
								items={steps.map((step, index) => ({
									title: step.title,
									disabled: !isStepAccessible(index),
								}))}
								className="p-2"
								onChange={async (current) => {
									// Before allowing any navigation, validate current step
									const isCurrentStepValid = await validateStep();

									if (!isCurrentStepValid) {
										// If current step is invalid, remove it from completedSteps
										setCompletedSteps((prev) => {
											const newSet = new Set(prev);
											newSet.delete(currentStep);
											return newSet;
										});

										// Only allow going back to previously completed steps
										if (current < currentStep && completedSteps.has(current)) {
											setCurrentStep(current);
										}
										return;
									}

									// If current step is valid, add it to completedSteps
									setCompletedSteps((prev) => new Set([...prev, currentStep]));

									// For the review step (last step), check if all required fields are filled
									if (current === steps.length - 1) {
										const isValid = await areAllRequiredFieldsFilled();
										if (!isValid) {
											notification.error({
												message: 'Incomplete Information',
												description: 'Please fill in all required fields before proceeding to review.',
												duration: 5,
											});
											return;
										}
									}

									// Allow navigation if the target step is accessible
									if (isStepAccessible(current)) {
										setCurrentStep(current);
									}
								}}
							/>
						</div>

						<div className="w-full md:w-3/4 shadow-border p-4 rounded-md flex flex-col justify-between bg-white">
							{steps[currentStep].content}

							<Space className="mt-4">
								{currentStep > 0 && <Button onClick={handleBack}>Back</Button>}
								{currentStep < steps.length - 1 && (
									<Button type="primary" onClick={handleNext}>
										Next
									</Button>
								)}
								{currentStep === steps.length - 1 && (
									<Button type="primary" onClick={showPublishConfirm} loading={isApplying}>
										Save Changes
									</Button>
								)}
							</Space>

							{errorMsg && <Alert style={{marginTop: '1rem'}} message={errorMsg} type="error" showIcon />}
						</div>
					</div>
				</Form>
			</div>

			<Modal title="Confirm Changes" open={isModalVisible} onOk={handlePublish} onCancel={() => setIsModalVisible(false)} okText="Yes, Save Changes" cancelText="Cancel">
				<p>Are you sure you want to save these changes to the job posting?</p>
			</Modal>
		</div>
	);
}

export default EditJobForm;
