import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {GoogleLogin} from '@react-oauth/google';
import {firebaseGoogleAuthApi, getUser, googleAuthApi, loginApi, loginApi2} from '../../api/authApi';
import {useMediaQuery} from '@mui/material';
import {getNotificationsApi} from '../../api/notificationsApi';
import {Alert, Button, Input, Spin} from 'antd';
import {GoogleCircleFilled, LoadingOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import Cookies from 'js-cookie';
import {COOKIES} from '../../utils/cookie-names';
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth';
import axios from 'axios';
import {mainUrl} from '../../api/Urls';
import {useAtom} from 'jotai';
import {unreadNotificationsAtom} from '../../utils/atoms';
import {ROUTES} from '../../utils/ROUTES';

export default function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [googleEmailError, setGoogleEmailError] = useState('');
	const [, setCurrentUser] = useContext(UserContext);
	const [disabledButton, setDisabledButton] = useState(true);
	const [, setNotifications] = useContext(NotificationContext);
	const [, setUnreadNotifications] = useAtom(unreadNotificationsAtom);

	// function to clear all errors
	function clearErrors() {
		setError(false);
		setGoogleEmailError('');
	}

	useEffect(() => {
		if (email && password) {
			setDisabledButton(false);
		} else {
			setDisabledButton(true);
		}
	}, [email, password]);

	async function getNotifications() {
		if (localStorage.getItem('accessToken')) {
			const res = await getNotificationsApi();
			console.log(res);
			setNotifications(res);
			setUnreadNotifications(res?.filter((notif) => notif.read === false).length);
			console.log('Successfully fetched notifications');
		}
	}

	const loginRedirect = () => {
		if (Cookies.get(COOKIES.unauthorized_redirect)) {
			navigate(Cookies.get(COOKIES.unauthorized_redirect));
			Cookies.remove(COOKIES.unauthorized_redirect);
		} else if (Cookies.get(COOKIES.post_job_redirect)) {
			navigate(Cookies.get(COOKIES.post_job_redirect));
			Cookies.remove(COOKIES.post_job_redirect);
		} else {
			navigate('/');
		}
	};

	const firebaseGoogleAuthApiLocal = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();

		try {
			const result = await signInWithPopup(auth, provider);
			setLoading(true);
			const user = result.user;
			const idToken = await user.getIdToken();
			const response = await axios.post(mainUrl + '/auth/firebase-google-auth/', {
				idToken: idToken,
			});
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error('Error during Google sign-in:', error);
			setLoading(false);
		}
	};

	async function firebaseGoogleLogin() {
		try {
			const response = await firebaseGoogleAuthApiLocal();
			if (!response?.refresh || !response?.access) {
				throw new Error('Invalid response from Google authentication');
			}
			console.log(response);
			console.log('refresh: ', response.refresh);
			localStorage.setItem('token', response.refresh);
			localStorage.setItem('accessToken', response.access);
			const userResponse = await getUser();
			if (!userResponse?.data) {
				throw new Error('Invalid user response');
			}
			setCurrentUser(userResponse.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			console.error(error);
			clearErrors();
			setGoogleEmailError(error?.response?.data?.detail || 'An error occurred during login');
			setLoading(false);
		}
	}

	async function login(e) {
		e.preventDefault();
		try {
			setLoading(true);
			const {data} = await loginApi(email.toLowerCase(), password);
			clearErrors();
			localStorage.setItem('token', data.tokens.refresh);
			localStorage.setItem('accessToken', data.tokens.access);
			const userData = await getUser();
			await setCurrentUser(userData.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			clearErrors();
			if (error.response.data.detail.includes('not verified')) {
				setError(
					<div className="text-sm">
						Your email is not verified. Please check your email for a verification link.
						<Link to={ROUTES.CONFIRM_EMAIL} className="text-blue-500 ml-1 hover:text-blue-600">
							Resend verification email
						</Link>
					</div>
				);
			} else {
				setError(error.response.data.detail);
			}
			setLoading(false);
		}
	}

	return (
		<div>
			<form onSubmit={login}>
				<div className="text">
					<h1>Hello again!</h1>
					<h2 className="text-lg">Sign into your account.</h2>
				</div>
				<div>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Email
					</p>
					<Input
						type="email"
						placeholder="Enter your email"
						required
						onChange={(e) => setEmail(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<MailOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Password
					</p>
					<Input
						type="password"
						placeholder="Enter your password"
						required
						onChange={(e) => setPassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
				</div>
				{error && <Alert message={error} type="error" showIcon className="mb-3 flex justify-start mt-2 rounded-md" banner />}
				{googleEmailError && <Alert message={googleEmailError} type="error" showIcon className="mb-3 mt-2 rounded-md" banner />}

				<Button
					htmlType="submit" // This specifies that the button is a submit button
					className="ButtonLinkBlue min-h-fit w-full m-0"
					disabled={disabledButton || loading}>
					Log in
				</Button>
				{loading && (
					<Alert
						message={<span className="ml-2">Signing in</span>}
						type="info"
						icon={
							<Spin
								indicator={
									<LoadingOutlined
										style={{
											fontSize: 16,
										}}
										spin
									/>
								}
								size="small"
							/>
						}
						banner
						className="mb-3 mt-2 rounded-md"
						style={{color: permColors.deepBlue}}
					/>
				)}
				<div className="divider-container">
					<div className="divider">
						<hr />
						<span className="text-xs">OR</span>
						<hr />
					</div>
				</div>

				<Button onClick={firebaseGoogleLogin} icon={<GoogleCircleFilled className="text-2xl py-1" style={{color: '#4285F4'}} />} className="w-full h-fit flex items-center justify-center">
					Continue with Google
				</Button>
			</form>
		</div>
	);
}
