import React, {useState, useEffect} from 'react';
import {requestPasswordResetEmail} from '../../api/profileApi';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import './Passwords.scss';
import {Helmet} from 'react-helmet';
import {Form, Input, Button, Alert, Card} from 'antd';
import {MailOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';

export default function ResetPassword() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);
	const [status, setStatus] = useState({
		type: '',
		message: '',
	});

	useEffect(() => {
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [timeoutId]);

	const onFinish = async (values) => {
		setLoading(true);
		setStatus({type: '', message: ''});

		if (timeoutId) {
			clearTimeout(timeoutId);
			setTimeoutId(null);
		}

		const res = await requestPasswordResetEmail(values.email);
		setLoading(false);

		if (res?.success) {
			setStatus({
				type: 'success',
				message: 'Email sent successfully, please check your email for a link to reset your password or wait 30 seconds to try again.',
			});
			setDisabled(true);
			const newTimeoutId = setTimeout(() => {
				setDisabled(false);
				setTimeoutId(null);
			}, 30000);
			setTimeoutId(newTimeoutId);
		} else if (res?.error) {
			setStatus({
				type: 'error',
				message: res.error,
			});
		}
	};

	return (
		<div>
			<Helmet>
				<title>Reset Password | Permworks</title>
				<meta name="description" content="Reset your Permworks account password. If you've forgotten your password or having trouble logging in, you can reset it here." />
			</Helmet>
			<Navbar />
			<div className="flex justify-center items-center min-h-[calc(100vh-200px)]">
				<Card className="w-full max-w-md">
					<div className="text-left mb-6">
						<h1 className="text-2xl font-semibold text-darkCharcoal">Request Password Reset</h1>
						<p className="text-charcoal">Enter your email to continue</p>
					</div>

					<Form form={form} name="reset-password" onFinish={onFinish} layout="vertical">
						<Form.Item
							className="mb-2"
							name="email"
							rules={[
								{
									required: true,
									message: 'Please input your email!',
								},
								{
									type: 'email',
									message: 'Please enter a valid email!',
								},
							]}>
							<Input prefix={<MailOutlined className="mr-2 opacity-50" />} placeholder="Enter your email" />
						</Form.Item>

						<Form.Item className="mb-2">
							<Button type="primary" htmlType="submit" loading={loading} disabled={disabled} className="w-full min-h-[40px]">
								Send Reset Link
							</Button>
						</Form.Item>
					</Form>
					{status.message && <Alert message={status.message} type={status.type} showIcon className="rounded-md" banner />}
				</Card>
			</div>
			<Footer />
		</div>
	);
}
