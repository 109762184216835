export const filterBySearchTerm = (searchTerm) => (job) => {
	return (job?.title?.toLowerCase?.() || '').includes(searchTerm || '') || (job?.company_name?.toLowerCase?.() || '').includes(searchTerm || '');
};

export const filterBySearchTermJobTitle = (searchTerm) => (job) => {
	return (job?.title?.toLowerCase?.() || '').includes(searchTerm || '') || (job?.company_name?.toLowerCase?.() || '').includes(searchTerm || '');
};

export const filterByJobType = (jobTypeFilter) => (job) => {
	return jobTypeFilter === 'all' || (job?.job_type?.toLowerCase?.() || '') === jobTypeFilter;
};

export const filterBySubscriptionStatus = (subscriptionStatusFilter) => (job) => {
	if (!job) return false;
	if (subscriptionStatusFilter === 'all') {
		return true; // Include all jobs
	} else if (subscriptionStatusFilter === 'inactive') {
		return !job?.stripe_subscription;
	} else if (subscriptionStatusFilter === 'active') {
		return !!job?.stripe_subscription;
	}
	return true; // Default case to include all jobs
};

export const filterByEngagementType = (engagementTypeFilter) => (job) => {
	return engagementTypeFilter === 'all' || (job?.engagement_type?.toLowerCase?.() || '') === engagementTypeFilter;
};

export const filterByApplicantName = (searchText, candidateFit) => (applicant) => {
	if (!applicant) return false;
	const fullName = `${applicant?.freelancer?.profile?.first_name || ''} ${applicant?.freelancer?.profile?.last_name || ''}`.toLowerCase();
	const matchesName = fullName.includes((searchText || '').toLowerCase());
	const matchesFit = candidateFit === 'All' || (applicant?.candidate_fit && applicant.candidate_fit === candidateFit);
	return matchesName && matchesFit;
};

export const filterByAllBillingHistory = (searchText) => (billingHistory) => {
	if (!billingHistory) return false;
	const searchTextLower = (searchText || '').toLowerCase();
	const matchesChargeId = (billingHistory?.charge_id?.toLowerCase?.() || '').includes(searchTextLower);
	const matchesJobId = billingHistory?.job && (billingHistory?.job?.id?.toLowerCase?.() || '').includes(searchTextLower);
	const matchesJobTitle = billingHistory?.job && (billingHistory?.job?.title?.toLowerCase?.() || '').includes(searchTextLower);
	const matchesCompanyName = billingHistory?.job && (billingHistory?.job?.company_name?.toLowerCase?.() || '').includes(searchTextLower);

	return matchesChargeId || matchesJobTitle || matchesCompanyName || matchesJobId;
};
