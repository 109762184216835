import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {FaTiktok, FaLinkedinIn, FaFacebookF, FaInstagram} from 'react-icons/fa';

interface SocialMediaLink {
	href: string;
	icon: JSX.Element;
}

interface OtherPageLink {
	to: string;
	label: string;
	external?: boolean;
}

export const socialMediaLinks: SocialMediaLink[] = [
	{href: 'https://www.linkedin.com/company/permworks/', icon: <FaLinkedinIn />},
	{href: 'https://www.facebook.com/profile.php?id=100085243007121', icon: <FaFacebookF />},
	{href: 'https://www.instagram.com/permworks/', icon: <FaInstagram />},
	{href: 'https://www.tiktok.com/@permworks', icon: <FaTiktok />},
];

export const otherPageLinks: OtherPageLink[][] = [
	[
		{to: ROUTES.BROWSE_JOBS, label: 'Find a Job'},
		{to: ROUTES.PRICING, label: 'Pricing'},
		{to: ROUTES.HOW_IT_WORKS, label: 'How it Works'},
		{to: ROUTES.BLOG, label: 'Blog'},
	],
	[
		{to: ROUTES.CONTACT_US, label: 'Contact Us'},
		{to: ROUTES.ABOUT_US, label: 'About Us'},
		{to: EXTERNAL_ROUTES.PERMHUNT, label: 'End to End Tech Recruitment', external: true},
		// {to: ROUTES.EMPLOYER_OF_RECORD, label: 'Employer of Record'},
		// {to: ROUTES.SALARY_GUIDE, label: 'Salary Guide'},
	],
	[
		{to: ROUTES.PRIVACY, label: 'Privacy Policy'},
		{to: ROUTES.TERMS_AND_CONDITIONS, label: 'Terms & Conditions'},
		{to: ROUTES.FAQ, label: 'FAQ'},
	],
];
