import {EXTERNAL_ROUTES, ROUTES} from '../ROUTES';

export const pricingData = [
	{
		title: 'End-to-End Tech Recruitment',
		price: '25% of the yearly full-time salary.',
		priceDescription: 'Minimum 3k USD charge.',
		descriptions: [
			'Job Description Write Up',
			'Job Advertisement to Top Candidates',
			'Candidate Screening',
			'Candidate Shortlisting',
			'Candidate Interviews',
			'Facilitation of Technical Assessments',
			'Personality Assessments',
			'Reference Checks',
			'Criminal Checks (NBI Clearance)',
			'Expert Guidance',
		],
		buttonText: 'Find Out More',
		buttonLink: EXTERNAL_ROUTES.PERMHUNT,
	},
];

export const subscriptionOptionsPricing = [
	{
		title: 'Lite Job Ad',
		price: 'Free',
		priceDescription: 'Single concurrent job ad',
		descriptions: ['Capped at 20 applicants - once you hit 20 applicants the job is closed', 'Max 1 free posting at a time', 'Max 1 concurrent Lite Job Ad'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
	},
	{
		title: 'Basic',
		price: '$35 USD',
		priceDescription: 'Per month / per job ad',
		descriptions: ['Unlimited applicants', 'Priority job ad placement in feed', 'Basic email blast to suitable job applicants in our database'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
	},
	{
		title: 'Premium',
		price: '$45 USD',
		priceDescription: 'Per month / per job ad',
		descriptions: ['Unlimited applicants', 'Higher priority Job ad placement in feed', 'Moderate email blast to suitable job applicants in our database'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
		recommended: true,
	},
];
