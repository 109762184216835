import React from 'react';
import {Modal, Button, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {COOKIES} from '../../utils/cookie-names';
import {ROUTES} from '../../utils/ROUTES';
const {Title, Paragraph} = Typography;

export default function GoogleModal({openGoogle, closeGoogle, dialogTitle, dialogContent, button1, button2, button1function, button2function, link, linkText, link2, linkText2}) {
	const navigate = useNavigate();

	const handleLinkClick = () => {
		navigate(link);
		if (dialogTitle === 'Login to Post') {
			Cookies.set(COOKIES.post_job_redirect, ROUTES.POST_JOB);
		}
		closeGoogle();
	};

	return (
		<Modal open={openGoogle} onCancel={closeGoogle} footer={null} width={450} centered>
			<Title level={4}>{dialogTitle}</Title>
			<Paragraph>{dialogContent}</Paragraph>
			<div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
				{link2 && <Button onClick={closeGoogle}>{linkText2}</Button>}
				{button1 && <Button onClick={button1function}>{button1}</Button>}
				{link && (
					<Button onClick={handleLinkClick} type="primary">
						{linkText}
					</Button>
				)}
				{button2 && <Button onClick={button2function}>{button2}</Button>}
			</div>
		</Modal>
	);
}
