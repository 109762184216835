import React, {useState} from 'react';
import {Typography, Form, Input, Radio, Select, Upload, Button, message, Alert, Card} from 'antd';
import {InfoCircleOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {createProfileApi} from '../../api/profileApi';
import {Helmet} from 'react-helmet';
import {countries} from '../../utils/arrayData/CountryArray';
import Loading from '../../components/Loaders/Loading';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {COOKIES} from '../../utils/cookie-names';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {permColors} from '../../utils/colors';

const {Title, Text} = Typography;

export default function CreateProfile() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [imageUrl, setImageUrl] = useState(null);
	const [userType, setUserType] = useState('');

	const onFinish = async (values) => {
		try {
			setLoading(true);
			const result = await createProfileApi(values.firstName, values.lastName, values.userType, values.country, values.companyName, values.profileImage?.fileList[0]?.originFileObj);

			if (!result.success) {
				message.error(result.error);
				return;
			}

			if (Cookies.get(COOKIES.apply_job_redirect)) {
				navigate(Cookies.get(COOKIES.current_location));
			} else {
				window.location.reload();
			}

			if (Cookies.get(COOKIES.post_job_redirect)) {
				navigate(Cookies.get(COOKIES.post_job_redirect));
			} else {
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
			message.error(error.toString());
		} finally {
			setLoading(false);
		}
	};

	const handleImageChange = (info) => {
		if (info.file.status === 'uploading') {
			return;
		}
		if (info.file.status === 'done') {
			// Get the preview URL of the uploaded image
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageUrl(e.target.result);
			};
			reader.readAsDataURL(info.file.originFileObj);
		} else if (info.file.status === 'error') {
			message.error('Image upload failed');
		}
	};

	const handleUserTypeChange = (e) => {
		setUserType(e.target.value);
		if (e.target.value === 'Job-Seeker') {
			form.setFieldValue('companyName', undefined);
		}
	};

	const renderAccessInfo = () => {
		const accessFeatures = {
			'Job-Seeker': 'Find and apply to jobs while managing your professional presence',
			Employer: 'Post and manage job listings while tracking candidates through the hiring process',
		};

		const accessInfoArray = {
			'Job-Seeker': ['Search and apply to jobs based on your skills and interests', 'Save and manage job applications', 'Receive job alerts and updates'],
			Employer: ['Search and manage resumes and applications', 'Receive job alerts and updates', 'Track the status of job applications'],
		};

		return (
			<Card className="mb-4 bg-backgroundGreyBlue">
				<div className="flex items-start gap-2 mb-3">
					<InfoCircleOutlined className="text-base text-blue-500" />
					<div className="flex flex-col gap-1">
						<div className="text-base font-medium leading-none">{userType} Access</div>
					</div>
				</div>
				<div className="text-sm">{accessFeatures[userType]}</div>
				<ul className="text-charcoal list-disc pl-4 mt-2">
					{accessInfoArray[userType].map((item, index) => (
						<li key={index} className="mb-1">
							{item}
						</li>
					))}
				</ul>
			</Card>
		);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="min-h-screen bg-backgroundGreyBlue">
			<Navbar />
			<Helmet>
				<title>Create Profile | Permworks</title>
				<meta name="description" content="Create your Permworks profile. Showcase your skills and experience to potential employers." />
			</Helmet>

			<div className="max-w-3xl mx-auto p-3">
				<Card className="">
					<Card className="text-left mb-8">
						<h1 className="text-darkCharcoal text-2xl mb-2 font-semibold">Create your profile</h1>
						<p className="text-darkCharcoal text-lg mb-1">Let's get started</p>
						<p className="text-darkCharcoal text-sm">Create your profile, open doors to new opportunities.</p>
					</Card>

					<Form form={form} layout="vertical" onFinish={onFinish}>
						<Form.Item label="I am a" name="userType" rules={[{required: true, message: 'Please select your user type'}]}>
							<Radio.Group buttonStyle="solid" className="w-full" onChange={handleUserTypeChange}>
								<div className="w-full flex">
									<Radio.Button value="Job-Seeker" className="text-center h-12 leading-[44px] flex-1">
										Job Seeker
									</Radio.Button>
									<Radio.Button value="Employer" className="text-center h-12 leading-[44px] flex-1">
										Employer
									</Radio.Button>
								</div>
							</Radio.Group>
						</Form.Item>

						{userType && renderAccessInfo()}

						<div className="grid grid-cols-2 gap-4">
							<Form.Item label="First Name" name="firstName" rules={[{required: true, message: 'Please enter your first name'}]}>
								<Input size="large" placeholder="Enter your first name" />
							</Form.Item>

							<Form.Item label="Last Name" name="lastName" rules={[{required: true, message: 'Please enter your last name'}]}>
								<Input size="large" placeholder="Enter your last name" />
							</Form.Item>
						</div>

						{userType === 'Employer' && (
							<Form.Item label="Company Name" name="companyName" rules={[{required: true, message: 'Please enter your company name'}]}>
								<Input size="large" placeholder="Enter your company name" />
							</Form.Item>
						)}

						<Form.Item label="Country" name="country" rules={[{required: true, message: 'Please select your country'}]}>
							<Select
								size="large"
								placeholder="Select your country"
								options={countries.map((country) => ({
									label: country,
									value: country,
									disabled: country === '----',
									className: country === '----' ? 'opacity-30' : '',
								}))}
							/>
						</Form.Item>

						<Form.Item label={userType === 'Job-Seeker' ? 'Profile Image' : 'Company Logo/Profile'} name="profileImage">
							<Upload
								maxCount={1}
								accept="image/png,image/jpeg,image/gif,image/heic,image/heif"
								listType="picture-circle"
								showUploadList={false}
								onChange={handleImageChange}
								customRequest={({file, onSuccess}) => {
									setTimeout(() => {
										onSuccess('ok');
									}, 0);
								}}>
								{imageUrl ? (
									<div className="w-24 h-24 rounded-full overflow-hidden">
										<img src={imageUrl} alt="Profile" className="w-full h-full object-cover" />
									</div>
								) : (
									<div className="flex flex-col items-center justify-center w-24 h-24 rounded-full">
										<div className="ant-upload-btn">
											<PlusOutlined style={{fontSize: '24px', color: '#8c8c8c'}} />
											<div className="ant-upload-text text-xs">Upload</div>
										</div>
									</div>
								)}
							</Upload>
						</Form.Item>

						<Form.Item className="mb-0">
							<Button type="primary" htmlType="submit" size="large" className="w-full bg-blue-600">
								Create Profile
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</div>
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
