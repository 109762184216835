import React, {useState, useEffect} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Skeleton} from 'antd';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {permColors} from '../../utils/colors';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({fileName}: {fileName: string}) {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	// Reset states when fileName changes
	useEffect(() => {
		setNumPages(null);
		setError(false);
		setIsLoading(true);
	}, [fileName]);

	const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
		setIsLoading(false);
		setError(false);
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error: Error) => {
		console.error('PDF load error:', error);
		setIsLoading(false);
		setError(true);
	};

	const Loading = () => <Skeleton active className="py-20 px-8" />;

	const ErrorMessage = () => <div className="p-4 text-center text-red-600">Failed to load PDF. Please try downloading the file directly.</div>;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div
				onClick={handleClickOpen}
				className="cursor-pointer max-h-[100vh] overflow-y-auto p-2 fun-border scroll-smooth w-full sticky top-0 hover:opacity-70 transition-opacity duration-300 ease-in-out"
				style={{backgroundColor: permColors.backgroundGrey}}>
				<Document
					file={fileName}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onDocumentLoadError}
					loading={<Loading />}
					error={<ErrorMessage />}
					options={{
						cMapUrl: 'https://unpkg.com/pdfjs-dist@3.4.120/cmaps/',
						cMapPacked: true,
					}}>
					{!error &&
						!isLoading &&
						numPages &&
						Array.from(new Array(numPages), (el, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} className="mb-2 shadow-sm w-full rounded" loading={<Loading />} />)}
				</Document>
			</div>
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<IconButton
					onClick={handleClose}
					aria-label="close"
					style={{
						position: 'fixed',
						right: 4,
						top: 4,
						color: '#fff',
						zIndex: 1201,
					}}>
					<CloseIcon />
				</IconButton>
				<Document
					file={fileName}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onDocumentLoadError}
					className="p-2"
					error={<ErrorMessage />}
					options={{
						cMapUrl: 'https://unpkg.com/pdfjs-dist@3.4.120/cmaps/',
						cMapPacked: true,
					}}>
					{!error &&
						!isLoading &&
						numPages &&
						Array.from(new Array(numPages), (el, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} className="mb-2 shadow-sm w-full rounded" loading={<Loading />} />)}
				</Document>
			</Dialog>
		</>
	);
}
