import React, {useEffect, useState} from 'react';
import './DashboardComponent.scss';
import {getMyJobsApi} from '../../api/jobsApi';
import {formatDate2, formatDateSevenDaysLater} from '../../utils/DateFunctions';
import {capitalizeFirstLetter} from '../../utils/TextAlterations';
import {cancelJobSubscription, createJobCheckoutSession} from '../../api/stripeApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import Loading from '../Loaders/Loading';
import {Alert, IconButton, Snackbar, useMediaQuery} from '@mui/material';
import QueueAnim from 'rc-queue-anim';
import {permColors} from '../../utils/colors';
import {ROUTES} from '../../utils/ROUTES';
import {filterByJobType, filterBySearchTerm, filterBySearchTermJobTitle, filterBySubscriptionStatus} from '../../utils/filters';
import {Close} from '@material-ui/icons';

import {Table, Button, Tag, Input, Select, Modal} from 'antd';
import {price_for_plan, renderSubscriptionButton, renderSubscriptionStatusTag, subscription_text} from './subscription-helpers';
import {useAtom} from 'jotai';
import {jobsAtom, jobsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';

export default function Subscriptions() {
	const [jobs, setJobs] = useAtom(jobsAtom);
	const [jobsTriggered, setJobsTriggered] = useAtom(jobsTriggeredAtom);
	const [loading, setLoading] = useState(true);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [isActivation, setIsActivation] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [errorMessage, setErrorMessage] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState('all');
	const [open, setOpen] = React.useState(false);
	const [severity, setSeverity] = React.useState('success');

	const handleConfirmation = () => {
		if (isActivation && selectedJob?.id && selectedJob?.subscription_type) {
			activateSubscription(selectedJob.id, selectedJob.subscription_type);
		} else if (selectedJob?.id) {
			cancelSubscription(selectedJob.id);
		}
		handleClose();
	};

	const fetchJobs = async () => {
		try {
			const res = await getMyJobsApi(pageNumber);
			setLoading(false);
			setJobs(res || []);
			setJobsTriggered(true);
		} catch (error) {
			console.error('Error fetching jobs:', error);
			setLoading(false);
			setJobs([]);
			setJobsTriggered(true);
		}
	};

	const showCase = (jobId, activation) => {
		if (!jobId) return;
		setIsActivation(activation);
		setSelectedJob(jobs?.find((job) => job?.id === jobId) || null);
		setShow(true);
	};

	const activateSubscription = async (id, subscriptionType) => {
		if (!id || !subscriptionType) return;

		setButtonLoader(true);
		try {
			const res = await createJobCheckoutSession(id, subscriptionType);

			if (res === 'success') {
				await fetchJobs();
				setErrorMessage('Subscription activated successfully');
				setSeverity('success');
			} else {
				setErrorMessage(
					<>
						An error occurred, please{' '}
						<a target="_blank" rel="noreferrer" className="standard-link" style={{color: permColors.white}} href={ROUTES.CONTACT_US}>
							contact us
						</a>{' '}
						if this issue persists.
					</>
				);
				setSeverity('error');
			}
		} catch (error) {
			console.error('Error activating subscription:', error);
			setErrorMessage('An error occurred while activating the subscription');
			setSeverity('error');
		} finally {
			setButtonLoader(false);
		}
	};

	const cancelSubscription = async (id) => {
		if (!id) return;

		setButtonLoader(true);
		try {
			const res = await cancelJobSubscription(id);
			if (res !== false) {
				await fetchJobs();
				setErrorMessage('Subscription cancelled successfully');
				setSeverity('success');
			} else {
				setErrorMessage(
					<>
						An error occurred, please{' '}
						<a target="_blank" rel="noreferrer" className="standard-link" style={{color: permColors.white}} href={ROUTES.CONTACT_US}>
							contact us
						</a>{' '}
						if this issue persists.
					</>
				);
				setSeverity('error');
				setOpen(true);
			}
		} catch (error) {
			console.error('Error cancelling subscription:', error);
			setErrorMessage('An error occurred while cancelling the subscription');
			setSeverity('error');
			setOpen(true);
		} finally {
			setButtonLoader(false);
		}
	};

	useEffect(() => {
		if (!jobsTriggered) {
			fetchJobs();
		} else {
			setLoading(false);
		}
	}, []);

	const handleCloser = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	if (loading) {
		return <LoadingPartial />;
	}

	if (buttonLoader) {
		return <Loading />;
	}

	const stripeInCancellationPeriod = (job) => {
		if (!job) return null;

		if (job?.stripe_in_cancellation_state) {
			return `The job ad expires on the ${formatDateSevenDaysLater(job?.stripe_subscription_date || '')} and will not be renewed`;
		}
		return job?.stripe_subscription_date ? formatDateSevenDaysLater(job.stripe_subscription_date) : null;
	};

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (title, record) => <a href={`/jobpage/${record?.id || ''}`}>{capitalizeFirstLetter(title)}</a>,
		},
		{
			title: 'Billing Amount',
			dataIndex: 'billingAmount',
			key: 'billingAmount',
			render: (_, record) => price_for_plan(record),
		},
		{
			title: 'Subscription plan',
			dataIndex: 'subscription_plan',
			key: 'subscriptionPlan',
			render: (_, record) => subscription_text(record),
		},
		{
			title: 'Subscription Start Date',
			dataIndex: 'stripe_subscription_date',
			key: 'startDate',
			render: (text) => (text ? formatDate2(text) : 'Not applicable'),
		},
		{
			title: 'Next Payment Date',
			dataIndex: 'stripe_subscription_date',
			key: 'nextPayment',
			render: stripeInCancellationPeriod,
		},
		{
			title: 'Subscription Status',
			dataIndex: 'stripe_subscription',
			key: 'status',
			render: (_, record) => renderSubscriptionStatusTag(record),
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => renderSubscriptionButton(record, showCase),
		},
	];

	const filteredJobs = (jobs || []).filter(filterBySearchTermJobTitle(searchTerm || '')).filter(filterBySubscriptionStatus(subscriptionStatusFilter || 'all'));

	return (
		<div className="dashboardComponent">
			<div className="header">
				<h2>Subscriptions</h2>
			</div>

			<div className="outerContainer">
				<div className="flex flex-wrap my-2 gap-2">
					<Input type="text" placeholder="Search by job title" onChange={(e) => setSearchTerm(e?.target?.value?.toLowerCase?.() || '')} className="p-2 w-[250px] mobile-width-100" />
					<Select
						defaultValue="all"
						className="w-[250px] mobile-width-100"
						options={[
							{label: 'All Subscriptions', value: 'all'},
							{label: 'Active', value: 'active'},
							{label: 'Inactive', value: 'inactive'},
						]}
						onChange={(e) => setSubscriptionStatusFilter(e || 'all')}
					/>
				</div>
				<div className="innerContainer">
					<div className="subscriptionContainer">
						<QueueAnim delay={200} interval={200}>
							<Table
								dataSource={filteredJobs}
								columns={columns}
								rowKey={(record) => record?.id || Math.random().toString()}
								pagination={{current: pageNumber, onChange: setPageNumber}}
								scroll={{x: true}}
							/>

							{(filteredJobs?.length || 0) === 0 && (
								<Alert severity="info" variant="filled" className="mt-2">
									You have no subscriptions at this time -{' '}
									<a href={ROUTES.POST_JOB} style={{color: permColors?.white}} className="underline">
										Post a job
									</a>
								</Alert>
							)}
						</QueueAnim>
						<Modal
							title={isActivation ? 'Activate Subscription' : 'Cancel Subscription'}
							open={show}
							onCancel={handleClose}
							footer={[
								<Button key="submit" type={isActivation ? 'primary' : 'default'} danger={!isActivation} onClick={handleConfirmation}>
									{isActivation ? 'Activate Subscription' : 'Cancel Subscription'}
								</Button>,
							]}>
							{isActivation
								? 'By pressing activate, your subscription will begin immediately, do you wish to continue?'
								: 'Are you sure you want to cancel this subscription? This action cannot be undone and your job ad will be removed from the site after the current billing period ends.'}
						</Modal>
						<Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
							<Alert onClose={handleCloser} severity={severity} variant="filled" sx={{width: '100%'}}>
								{errorMessage}
							</Alert>
						</Snackbar>
					</div>
				</div>
			</div>
		</div>
	);
}
