import React, {useEffect, useContext} from 'react';
import {Alert, Divider, List, ListItem, ListItemText} from '@mui/material';
import {NotificationContext} from '../../../utils/UserContext';
import {formatDate2} from '../../../utils/DateFunctions';
import './Notifications.scss';
import QueueAnim from 'rc-queue-anim';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../utils/ROUTES';
import {useAtom} from 'jotai';
import {selectedAtom, unreadNotificationsAtom} from '../../../utils/atoms';
import {permColors} from '../../../utils/colors';
import {markAllAsReadApi} from '../../../api/notificationsApi';
export default function Notifications() {
	const [notifications, setNotifications] = useContext(NotificationContext);

	const navigate = useNavigate();
	const [, setSelected] = useAtom(selectedAtom);

	const textStyles = {
		read: {},
		notRead: {
			backgroundColor: '#d8eefe',
		},
	};

	const dividerStyles = {
		backgroundColor: '#d8eefe00',
		height: '5px',
		border: 'none',
	};

	useEffect(() => {
		document.title = 'Notifications | JobFinder';
	}, []);

	const handleNotificationClick = (notif) => {
		switch (true) {
			case notif.message.toLowerCase().includes('new application '):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job));
				break;
			case notif.message.toLowerCase().includes('new message'):
				setSelected('inbox');
				navigate(ROUTES.DASHBOARD);
				break;
			case notif.message.includes('new job offer'):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job));
				break;
			default:
				break;
		}
	};

	return (
		<div className="dashboardComponent">
			<div className="header">
				<h2>Notifications</h2>
			</div>

			<div className="outerContainer">
				<div className="innerContainer">
					<div className="notifications">
						<List className="list">
							<Divider style={dividerStyles} />
							<QueueAnim delay={200} interval={130}>
								{notifications.length > 0 ? (
									notifications?.map((notif) => (
										<div key={notif?.id}>
											<ListItem sx={notif?.read ? textStyles.read : textStyles.notRead} className="listItem cursor-pointer" onClick={() => handleNotificationClick(notif)}>
												<ListItemText primary={notif?.message} primaryTypographyProps={{color: permColors.darkCharcoal}} secondary={formatDate2(notif?.created_at)} />
											</ListItem>
											<Divider style={dividerStyles} />
										</div>
									))
								) : (
									<>
										<Alert severity="info">You have no notifications yet. Check back later.</Alert>
									</>
								)}
							</QueueAnim>
						</List>
					</div>
				</div>
			</div>
		</div>
	);
}
