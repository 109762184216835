import axios from 'axios';
import {mainUrl} from './Urls';

export const contactUsApi = async (email, name, message, captchaToken) => {
	try {
		const data = {
			email: email,
			name: name,
			message: message,
			captchaToken: captchaToken,
		};
		await axios.post(mainUrl + '/auth/contact-us/', data);
		return true;
	} catch (error) {
		if (error?.response?.data?.error?.includes('CAPTCHA')) {
			return {error: 'captcha', message: 'There was an error with the CAPTCHA. Please refresh the page and try again.'};
		}
		return false;
	}
};
