import React, {useState, useEffect, useContext} from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate, Link} from 'react-router-dom';
import EndToEnd from './pages/EndToEndPage/EndToEnd';
import ResetPassword from './pages/ResetPasswordPages/ResetPassword';
import ResetPasswordConfirm from './pages/ResetPasswordPages/ResetPasswordConfirm';
import JobsHome from './pages/BrowseJobsPage/JobsHome';
import AuthPage from './pages/AuthPage/AuthPage';
import PricingPage from './pages/PricingPage/PricingPage';
import ContactUs from './pages/ContactUsPage/ContactUs';
import AboutUs from './pages/AboutPage/AboutUs';
import HowItWorks from './pages/HowItWorksPage/HowItWorks';
import Privacy from './pages/PrivacyPage/Privacy';
import Terms from './pages/TermsPage/Terms';
import HiringPhilippines from './pages/HiringPhilippinesPage/HiringPhilippines';
import Faq from './pages/FaqPage/Faq';
import {NotificationContext, UserContext} from './utils/UserContext';
import {Navigate} from 'react-router-dom';
import Dashboard from './pages/DashboardPage/Dashboard';
import ConfirmEmail from './pages/ConfirmEmailPage/ConfirmEmail';
import {GoogleOAuthProvider} from '@react-oauth/google';
import Loading from './components/Loaders/Loading';
import LandingPage from './pages/LandingPage/LandingPage';
import NotFound from './pages/NotFoundPage/NotFound';
import CreateProfile from './pages/ProfileCreatePage/CreateProfile';
import JobPage from './pages/JobPage/JobPage';
import EditEmployerProfile from './pages/ProfileEditPage/EditEmployerProfile';
import EditJobSeekerProfile from './pages/ProfileEditPage/EditJobSeekerProfile';
import OtherProfile from './pages/OtherProfile/OtherProfile';
import AuthenticatedCheck from './utils/arrayData/AuthenticatedCheck';
import PostJobForm from './pages/PostJob/PostJobForm';
import theme from './theme';
import {ThemeProvider} from '@mui/material';
import Applicants from './pages/ApplicantsPage/Applicants';
import PageTracker from './PageTracker';
import {getUserApi, refreshTokenApi} from './api/profileApi';
import ContractPage from './pages/ContractPage/ContractPage';
import ContractSuccess from './pages/ContractPage/ContractSuccess';
import {getNotificationsApi, saveFCMTokenApi} from './api/notificationsApi';
import BlogIndex from './pages/BlogPages/BlogIndex';
import {BlogContext} from './utils/arrayData/blogData';
import BlogPost from './pages/BlogPages/BlogPost';
import {BlogDataProvider} from './utils/blog/BlogDataProvider';
import {useDocumentHeight, useDocumentWidth} from './utils/appHelpers/appHeight';
import SalaryGuide from './pages/SalaryGuide/SalaryGuide';
import EmployerOfRecord from './pages/EmployerOfRecord/EmployerOfRecord';
import {useLocation} from 'react-router-dom';
import {ROUTES} from './utils/ROUTES';
import EmailVerify from './pages/EmailVerify/EmailVerify';
import {hasCreatedProfile, isEmployer, isJobSeeker, userExists, user_type} from './utils/variables/userVariables';
import GoogleModal from './components/Popups/GoogleModal';
import {antDapi, openNotification} from './components/Dashboard/EOR/Team';
import CookieConsent from './components/cookie-consent/CookieConsent';
import Cookies from 'js-cookie';
import {firebaseLogout} from './api/authApi';
import EorDashboardPage from './pages/EorDashboardPage/EorDashboardPage';
import EorVerification from './pages/EorVerification/EorVerification';
import {COOKIE_EXPIRY_TIME} from './utils/cookie-names';
import {COOKIES} from './utils/cookie-names';
import EditJobPage from './pages/EditJob/EditJobPage';
import {useAtom} from 'jotai';
import {unreadNotificationsAtom} from './utils/atoms';

function ScrollToTop({children}) {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return children;
}

const App = () => {
	const [currentUser, setCurrentUser] = useState(UserContext);
	const [loading, setLoading] = useState(true);
	const [fetchingUser, setFetchingUser] = useState(false);
	const [notifications, setNotifications] = useState([]); // Add this
	const [notificationsLoader, setNotificationsLoader] = useState(true); // Add this
	const [blogInfo, setBlogInfo] = useState([]);
	const [googleModal, setGoogleModal] = useState(false);
	const [dialogContent, setDialogContent] = useState(
		<p>
			You need to be logged in as an <strong>employer</strong> to post a job.
		</p>
	);
	const [unreadNotifications, setUnreadNotifications] = useAtom(unreadNotificationsAtom);

	useDocumentHeight();
	useDocumentWidth();

	function logout(setCurrentUser) {
		localStorage.setItem('accessToken', '');
		localStorage.setItem('token', '');
		setCurrentUser('');
		setUnreadNotifications(0);
		setNotifications([]);
		firebaseLogout();

		window.location.reload();
	}

	async function getNotifications() {
		if (localStorage.getItem('accessToken')) {
			const res = await getNotificationsApi();
			console.log(res);
			setNotifications(res);
			setUnreadNotifications(res?.filter((notif) => notif.read === false).length);
			setNotificationsLoader(false);
			console.log('Successfully fetched notifications');
		}
	}

	const fetchUser = async (retry = true) => {
		try {
			const response = await getUserApi(localStorage.getItem('accessToken'));
			saveFCMTokenApi();
			setCurrentUser(response.data);
			await getNotifications();
			console.log('Successfully fetched user on first try');
		} catch (error) {
			console.log(error);
			console.log('Could not fetch the user, trying to refresh token...');
			if (retry) {
				await refreshTokenAndFetchUser();
			} else {
				console.log('Fetch user failed after retrying, logging out the user...');
				logout(setCurrentUser);
			}
		} finally {
			setFetchingUser(false);
		}
	};

	const refreshTokenAndFetchUser = async () => {
		try {
			const response = await refreshTokenApi(localStorage.getItem('token'));
			localStorage.setItem('accessToken', response.data.access);
			await fetchUser(false); // Set retry to false
			console.log('Successfully refreshed token and fetched user');
		} catch (refreshError) {
			console.log(refreshError);
			console.log('Refreshing token also failed, logging out the user...');
			logout(setCurrentUser);
		}
	};

	useEffect(() => {
		const initApp = async () => {
			if (localStorage.getItem('accessToken')) {
				setFetchingUser(true);
				await fetchUser();
			}
			setLoading(false);
			setNotificationsLoader(false);
		};

		initApp();
	}, []);

	const ProtectedCreateProfile = ({children}) => {
		return currentUser?.profile?.first_name === null ? <Navigate to="/editprofile" /> : children;
	};

	const ProtectedAuth = () => {
		return currentUser?.profile ? <Navigate to="/" /> : <AuthPage />;
	};

	const ProtectedDashboard = () => {
		return currentUser?.profile ? <Dashboard /> : <Navigate to="/authenticate" />;
	};

	const CheckUserType = () => {
		if (currentUser.profile) {
			if (!hasCreatedProfile(currentUser)) {
				return <CreateProfile />;
			}
			if (isEmployer(currentUser)) {
				return <EditEmployerProfile />;
			} else if (isJobSeeker(currentUser)) {
				return <EditJobSeekerProfile />;
			}
		} else {
			return <Navigate to="/" />;
		}
	};

	const CheckPostJobUserType = ({children}) => {
		if (userExists(currentUser)) {
			if (isEmployer(currentUser)) {
				return <PostJobForm />;
			} else if (!hasCreatedProfile(currentUser)) {
				Cookies.set(COOKIES.post_job_redirect, ROUTES.POST_JOB, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
				return <Navigate to={ROUTES.EDIT_PROFILE} />;
			} else if (isJobSeeker(currentUser)) {
				antDapi.destroy();
				openNotification(
					'Job seeker account',
					<p>
						Your account is a job seeker account, to post a job please create a new account or request a change of account type by contacting us{' '}
						<a className="text-blue underline" href={ROUTES.CONTACT_US}>
							here
						</a>
						.
					</p>,
					'error',
					true,
					15
				);
				return <Navigate to={ROUTES.HOME} />;
			}
		} else {
			setGoogleModal(true);
			return <Navigate to={ROUTES.HOME} />;
		}
	};

	if (loading || fetchingUser || notificationsLoader) {
		return <Loading />;
	}

	return (
		<ThemeProvider theme={theme}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
				<UserContext.Provider value={[currentUser, setCurrentUser]}>
					<NotificationContext.Provider value={[notifications, setNotifications]}>
						<BlogContext.Provider value={[blogInfo, setBlogInfo]}>
							<Router>
								<PageTracker />
								<CookieConsent />
								<ScrollToTop>
									<Routes>
										<Route exact path={ROUTES.HOME} element={<LandingPage />} />
										<Route path={ROUTES.EOR_DASHBOARD} element={<EorDashboardPage />} />

										{/* PROFILE PAGES ////////////////////// */}
										<Route path={ROUTES.EDIT_PROFILE} element={<CheckUserType />} />
										<Route path={ROUTES.AUTHENTICATE} element={<ProtectedAuth />} />
										<Route path={`${ROUTES.AUTHENTICATE}/:hello`} element={<ProtectedAuth />} />
										<Route exact path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
										<Route exact path={ROUTES.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirm />} />
										<Route path={ROUTES.CONFIRM_EMAIL} element={<ConfirmEmail />} />
										<Route path={ROUTES.EMAIL_VERIFY} element={<EmailVerify />} />

										{/* OTHER PAGES ////////////////////// */}
										<Route path={ROUTES.CREATE_CONTRACT} element={<ContractPage />} />
										<Route path={ROUTES.CONTRACT_SUCCESS} element={<ContractSuccess />} />
										<Route path={ROUTES.EOR_VERIFICATION} element={<EorVerification />} />
										<Route path={ROUTES.APPLICANTS} element={<Applicants />} />
										<Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
										<Route
											path={ROUTES.USER_PROFILE}
											element={
												<AuthenticatedCheck>
													<OtherProfile />
												</AuthenticatedCheck>
											}
										/>
										<Route
											path={ROUTES.DASHBOARD}
											element={
												<ProtectedCreateProfile>
													<ProtectedDashboard />
												</ProtectedCreateProfile>
											}
										/>

										{/* INFO PAGES ////////////////////// */}
										<Route path={ROUTES.PRIVACY} element={<Privacy />} />
										<Route path={ROUTES.TERMS_AND_CONDITIONS} element={<Terms />} />
										<Route path={ROUTES.HIRING_IN_THE_PHILIPPINES} element={<HiringPhilippines />} />
										<Route exact path={ROUTES.END_TO_END_RECRUITMENT} element={<EndToEnd />} />
										<Route path={ROUTES.FAQ} element={<Faq />} />
										<Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
										<Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
										<Route exact path={ROUTES.SALARY_GUIDE} element={<SalaryGuide />} />
										<Route exact path={ROUTES.EMPLOYER_OF_RECORD} element={<EmployerOfRecord />} />
										<Route exact path={ROUTES.PRICING} element={<PricingPage />} />

										{/* JOB PAGES ////////////////////// */}
										<Route path={ROUTES.POST_JOB} element={<CheckPostJobUserType />} />
										<Route exact path={ROUTES.BROWSE_JOBS} element={<JobsHome />} />
										<Route exact path={ROUTES.JOB_PAGE} element={<JobPage />} />
										<Route exact path={ROUTES.EDIT_JOB} element={<EditJobPage />} />

										<Route path={ROUTES.NOT_FOUND} element={<NotFound />} />

										{/* BLOG PAGES ////////////////////// */}
										<Route
											exact
											path={ROUTES.BLOG}
											element={
												<BlogDataProvider>
													<BlogIndex />
												</BlogDataProvider>
											}
										/>
										<Route
											path={ROUTES.BLOG_POST}
											element={
												<BlogDataProvider>
													<BlogPost />
												</BlogDataProvider>
											}
										/>

										<Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
									</Routes>
								</ScrollToTop>
								<GoogleModal
									openGoogle={googleModal}
									dialogTitle="Login to Post"
									dialogContent={dialogContent}
									link={ROUTES.AUTHENTICATE}
									linkText="Login"
									link2={ROUTES.HOME}
									linkText2="Cancel"
									closeGoogle={() => setGoogleModal(false)}
								/>
							</Router>
						</BlogContext.Provider>
					</NotificationContext.Provider>
				</UserContext.Provider>
			</GoogleOAuthProvider>
		</ThemeProvider>
	);
};

export default App;
