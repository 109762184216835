export function formatCurrency(amountFrom, amountTo, rateType, currency) {
	if (!amountFrom || !amountTo || !rateType || !currency) {
		return '';
	}

	const formattedRateType = rateType ? `/${rateType}` : '';

	switch (currency) {
		case 'USD':
			return `$${amountFrom} - $${amountTo}${formattedRateType} (USD)`;
		case 'PHP':
			return `₱${amountFrom} - ₱${amountTo}${formattedRateType} (PHP)`;
		case 'CAD':
			return `CA$${amountFrom} - CA$${amountTo}${formattedRateType} (CAD)`;
		case 'AUD':
			return `A$${amountFrom} - A$${amountTo}${formattedRateType} (AUD)`;
		case 'NZD':
			return `NZ$${amountFrom} - NZ$${amountTo}${formattedRateType} (NZD)`;
		default:
			return `${amountFrom} - ${amountTo}${formattedRateType} (${currency})`;
	}
}
