import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {validatePasswordResetToken} from '../../api/profileApi';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {Helmet} from 'react-helmet';
import {Form, Input, Button, Alert, Card} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';

const ResetPasswordConfirm = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({
		type: '',
		message: '',
	});
	const {uidb64, token} = useParams();

	const handleSubmit = async (values) => {
		setStatus({type: '', message: ''});
		if (values.newPassword !== values.confirmPassword) {
			setStatus({
				type: 'error',
				message: 'Passwords do not match',
			});
			return;
		}

		setLoading(true);
		const res = await validatePasswordResetToken(uidb64, token, values.newPassword);
		setLoading(false);
		console.log(res);

		setStatus({
			type: res.includes('error') || res.includes('Invalid') ? 'error' : 'success',
			message: res,
		});
	};

	return (
		<div>
			<Helmet>
				<title>Reset Password | Permworks</title>
				<meta name="description" content="Reset your Permworks account password. If you've forgotten your password or having trouble logging in, you can reset it here." />
			</Helmet>
			<Navbar />
			<div className="flex justify-center items-center min-h-[calc(100vh-200px)] bg-backgroundGreyBlue">
				<Card className="w-full max-w-md">
					<div className="text-left mb-6">
						<h1 className="text-2xl font-bold text-darkCharcoal">Reset Password</h1>
						<p className="text-charcoal">Enter your new password</p>
					</div>

					<Form form={form} onFinish={handleSubmit} layout="vertical">
						<Form.Item
							name="newPassword"
							className="mb-2"
							rules={[
								{
									required: true,
									message: 'Please input your new password!',
								},
								{
									min: 6,
									message: 'Password must be at least 6 characters long',
								},
							]}>
							<Input.Password allowClear prefix={<LockOutlined className="opacity-50 mr-2" />} placeholder="New Password" />
						</Form.Item>

						<Form.Item
							name="confirmPassword"
							className="mb-4"
							rules={[
								{
									required: true,
									message: 'Please confirm your password!',
								},
							]}>
							<Input.Password allowClear prefix={<LockOutlined className="opacity-50 mr-2" />} placeholder="Confirm Password" />
						</Form.Item>

						<Form.Item className="mb-2">
							<Button type="primary" htmlType="submit" loading={loading} className="w-full min-h-[40px]">
								Reset Password
							</Button>
						</Form.Item>
					</Form>

					{status.message && <Alert message={status.message} type={status.type} showIcon className="rounded-md" banner />}
				</Card>
			</div>
			<Footer backgroundColor={permColors.white} />
		</div>
	);
};

export default ResetPasswordConfirm;
