import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './AboutUs.scss';
import {Helmet} from 'react-helmet';
import philLadies from '../../assets/images/philLadies.jpeg';
import timothy from '../../assets/images/timothy.jpeg';
import {BiLayer} from 'react-icons/bi';
import {BsPeople, BsCircle, BsEye, BsLightningCharge} from 'react-icons/bs';
import {FiBook} from 'react-icons/fi';
import {permColors} from '../../utils/colors';
const SegmentPart = ({Icon, title, text}) => {
	return (
		<div className="segment-part">
			<Icon className="icon" />
			<h3>{title}</h3>
			<p>{text}</p>
		</div>
	);
};

const AboutUs = () => {
	const aboutUsText = [
		'At Permworks, our mission is to make global teams a reality.',
		'Great talent does not only exist within the confines of a 30km radius of your office. So we set out to create a simple, integrated platform to help you find and hire amazing talent from around the world, starting with the Philippines.',
		`We guide employers through the Filipino hiring process, ensuring transparent engagements and best practices for international recruitment.`,
		`Our platform integrates all aspects of international hiring, from compliance and payroll to workspace solutions, supporting roles from Virtual Assistants to Senior Developers.`,
	];
	return (
		<div>
			<Helmet>
				<title>About Permworks | Permworks</title>
				<meta
					name="description"
					content="Learn more about Permworks. We are on a mission to make global teams possible. We're starting with the Philippines and going global. Join us on our journey."
				/>
			</Helmet>
			<Navbar2 />
			<div className="about-us">
				<div className="first-segment">
					<div className="left-side">
						<h1 className="h1-mini">ABOUT US</h1>
						<h2 className="h2-about-us">We are making direct Filipino hires possible</h2>
						{aboutUsText.map((text, index) => (
							<p className="mb-2" key={index}>
								{text}
							</p>
						))}
					</div>
					<img src={philLadies} alt="Philippines" className="object-cover" />
				</div>
				{/* <div className="second-segment">
					<div className="h1-mini">KEY METRICS</div>
					<h2 className="h2-about-us">Strength in numbers</h2>
					<div className="metric-container">
						<div className="metric">
							<h3>15,000 +</h3>
							<p>Applications Made</p>
						</div>
						<div className="metric">
							<h3>8500 +</h3>
							<p>Users</p>
						</div>
						<div className="metric">
							<h3>150 +</h3>
							<p>Roles Opened</p>
						</div>
					</div>
				</div> */}
				<div className="third-segment">
					<div className="h1-mini">CORE VALUES</div>
					<h2 className="h2-about-us-less-margin">How we work</h2>

					<p>Guided by our core values, we strive to exceed expectations and create meaningful experiences</p>
					<br />
					<div className="segmentations">
						<SegmentPart Icon={BiLayer} title="Innovation" text="We must strive to be the best by constantly innovating on all fronts." />
						<SegmentPart
							Icon={BsPeople}
							title="Clients first"
							text="Our clients are at the center of everything we do, and we are dedicated to providing them with the best possible experience and support."
						/>
						<SegmentPart Icon={BsCircle} title="Teamwork" text="Great companies are built by cohesive teams and we like to pride ourselves on that." />
						<SegmentPart Icon={BsEye} title="Accountability" text="We take responsibility in ensuring our client's success." />
						<SegmentPart Icon={FiBook} title="Continuous learning" text="We seek to improve day by day on a professional and personal level." />
						<SegmentPart Icon={BsLightningCharge} title="Speed" text="We like to move fast and are prompt in our communications." />
					</div>
				</div>
				{/* <div className="fourth-segment">
					<div className="h1-mini">PEOPLE</div>
					<h2 className="h2-about-us">Meet the executive team</h2>
					<br />
					<img src={timothy} alt="Timothy" />
					<h3 className="h3-about-us">Tim Nicholas</h3>
					<p className="h1-mini" style={{fontWeight: 'bold', margin: '0'}}>
						Co-founder & CEO
					</p>
				</div> */}
				<div className="fifth-segment">
					<div className="h1-mini">LOCATIONS</div>
					<h2 className="h2-about-us">Our locations</h2>
					<div className="location-container">
						<div className="location-card">
							<h3 className="h3-about-us-bottom-margin">Sydney</h3>
							<p style={{color: permColors.charcoal}}>11 York Street Sydney NSW 2000 Australia</p>
						</div>
						<div className="location-card">
							<h3 className="h3-about-us-bottom-margin">Manila</h3>
							<p style={{color: permColors.charcoal}}>5/F Phinma Plaza, 39 Plaza Dr, Makati, 1200 Metro Manila Philippines</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AboutUs;
