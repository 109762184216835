import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './ApplyJobPopup.scss';
import {applyJobApi} from '../../api/jobsApi';
import {Alert, CircularProgress} from '@mui/material';
import {Drawer, Form, Input, Button, Upload, Modal, Popconfirm} from 'antd';
import {permColors} from '../../utils/colors';
import {CheckCircleFilled, CheckOutlined, CloseOutlined, InboxOutlined} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';
import {useAtom} from 'jotai';
import {appliedJobRetriggerAtom} from '../../utils/atoms';
import {message as antdMessage} from 'antd';
const {Dragger} = Upload;

export default function ApplyJobPopup(props) {
	const {showPopup, message, closePopup, link, linkName, title, textArea, buttonText, disabledButtonText, buttonFunction, user, job} = props;
	const [responseMessage, setResponseMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [file, setFile] = useState(null);
	const [answers, setAnswers] = useState({});
	const [coverLetterOrMessage, setCoverLetterOrMessage] = useState('');
	const [isApplying, setIsApplying] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [modal2Open, setModal2Open] = useState(false);
	const [modal3Open, setModal3Open] = useState(false);
	const navigate = useNavigate();
	const [appliedJobTrigger, setAppliedJobTrigger] = useAtom(appliedJobRetriggerAtom);

	const handleAnswerChange = (questionId, answer) => {
		setAnswers({
			...answers,
			[questionId]: answer,
		});
	};

	const submit = async (values) => {
		setIsApplying(true);
		const answerList = Object.entries(answers).map(([question, answer]) => ({
			question,
			answer,
		}));
		const res = await applyJobApi(job.id, file, user.id, JSON.stringify(answerList), coverLetterOrMessage);
		if (res === true) {
			setResponseMessage('You have successfully applied for this job!');
			setSeverity('success');
			setDisabledButton(true);

			if (!user?.has_applied_for_first_job) {
				setModal2Open(true);
			} else {
				setAppliedJobTrigger(true);
			}
			antdMessage.success('Job application submitted successfully!');
		}
		if (res === false) {
			setResponseMessage('Something went wrong, please refresh the page.');
			setSeverity('error');
		}
		setIsApplying(false);
	};

	function closeEverything() {
		setModal2Open(false);
		setModal3Open(false);
		closePopup();
		window.location.reload();
	}

	async function navigateToProfile(isSecondModal = false) {
		navigate(ROUTES.EDIT_PROFILE);
	}

	async function declineModal() {
		closeEverything();
	}

	return (
		<Drawer
			title={
				<span className="font-normal text-left">
					Apply for <span className="font-semibold">{job.title}</span>
				</span>
			}
			placement="right"
			onClose={closePopup}
			visible={showPopup}
			width={1000}
			zIndex={2000}
			extra={
				<div className="flex gap-2">
					<Button icon={<CheckOutlined />} className="flex-1" type="primary" htmlType="submit" disabled={disabledButton || isApplying} form="applyForm">
						{isApplying ? <CircularProgress size={24} sx={{color: permColors.white}} /> : buttonText}
					</Button>
					<Button icon={<CloseOutlined />} className="flex-1 min-h-fit" onClick={closePopup} danger>
						Cancel
					</Button>
				</div>
			}
			closeIcon={false}
			className="flex items-top">
			<Form onFinish={submit} layout="vertical" id="applyForm">
				<p>{message}</p>
				{job.questions.length > 0 && (
					<div key={job.id}>
						<p className="text-lg font-semibold">Please answer the following required questions</p>
						{job.questions.map((question) => (
							<Form.Item
								label={<span>{question.question}</span>}
								key={question.id}
								name={`question_${question.id}`}
								className="text-left"
								rules={[{required: true, message: `Please answer the question: ${question.question}`}]}>
								<Input.TextArea onChange={(e) => handleAnswerChange(question.id, e.target.value)} />
							</Form.Item>
						))}
					</div>
				)}
				{job.provide_cover_letter && (
					<Form.Item label={<span>Upload your CV</span>} name="cv" rules={[{required: true, message: 'Please upload your CV'}]}>
						<Dragger
							beforeUpload={(file) => {
								setFile(file);
								return false;
							}}
							accept=".pdf,.doc,.docx"
							maxCount={1}>
							<InboxOutlined className="text-3xl mb-1" />
							<p className="ant-upload-text">Click or drag file to this area to upload</p>
							<p className="ant-upload-hint mb-2">Support for a single upload. Strictly prohibit from uploading company data or other sensitive files.</p>
						</Dragger>
					</Form.Item>
				)}
				<Form.Item label="Write a cover letter or leave a message (optional)">
					<Input.TextArea onChange={(e) => setCoverLetterOrMessage(e.target.value)} />
				</Form.Item>
				{responseMessage && (
					<Alert className="mb-3" severity={severity}>
						{responseMessage}
					</Alert>
				)}
				{link && <Link to={link}></Link>}
				<div className="flex gap-2 w-fit">
					<Button icon={<CheckOutlined />} className="flex-1" type="primary" htmlType="submit" disabled={disabledButton || isApplying} form="applyForm">
						{isApplying ? <CircularProgress size={24} sx={{color: permColors.white}} /> : buttonText}
					</Button>
					<Button icon={<CloseOutlined />} className="flex-1 min-h-fit" onClick={closePopup} danger>
						Cancel
					</Button>
				</div>
			</Form>
			<Modal
				title={
					<p className="text-2xl flex items-center gap-2" style={{color: permColors.darkCharcoal}}>
						<CheckCircleFilled className=" text-success-600" /> Job Application Submitted!
					</p>
				}
				centered
				maskClosable={false}
				closeIcon={false}
				open={modal2Open}
				onOk={navigateToProfile}
				onCancel={() => setModal3Open(true)}
				okText="Fill out profile!"
				cancelText="Ive already filled out my profile">
				<div style={{color: permColors.charcoal}}>
					<p>Congratulations on making your first job application!</p>
					<p>Did you know that users with detailed profiles are 50% more likely to get responses from job applications?</p>
					<p>Fill out your profile now to increase your chances of getting hired!</p>
				</div>
			</Modal>
			<Modal
				title="Are you sure you have filled out your profile?"
				centered
				maskClosable={false}
				closeIcon={false}
				open={modal3Open}
				onOk={declineModal}
				onCancel={navigateToProfile}
				okText="Yes, I have"
				cancelText="No, I haven't"
				keyboard={false}
				className="max-w-[400px]">
				<div style={{color: permColors.charcoal}}>
					<p className="text-sm">Are you sure you have filled out your profile?</p>
					<p className="text-sm">Users with detailed profiles are 50% more likely to get responses from job applications.</p>
					<p className="text-sm">Fill out your profile now to increase your chances of getting hired!</p>
				</div>
			</Modal>
		</Drawer>
	);
}
