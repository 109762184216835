import React from 'react';
import './LandingPage.scss';
import {capitalizeFirstLetter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {WorkOutlined} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {Button, message} from 'antd';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {CopyOutlined} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';

interface JobCardProps {
	id: string;
	job: any;
	isSelected: boolean;
	onJobSelect: any;
	authenticatedButtons?: any;
}

export function JobCard({id, job, isSelected, onJobSelect, authenticatedButtons}: JobCardProps) {
	return (
		<React.Fragment>
			<div
				id={id}
				onClick={() => onJobSelect(job)}
				className={`cardStyle cursor-pointer text-left p-4 rounded-lg flex flex-col justify-between gap-1 md:w-[500px] max-w-full ${isSelected ? 'selectedAllCards' : ''}`}>
				<div className="flex items-center justify-between gap-2 mb-4">
					<div className="rounded-full border border-gray-200 p-2 w-fit">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon " /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					</div>
					<div className="flex flex-row items-end gap-2">
						<Button
							className="rounded-md min-h-fit"
							onClick={() => {
								navigator.clipboard.writeText(window.location.origin + ROUTES.JOB_PAGE.replace(':jobId', job.id));
								message.success('Job URL copied to clipboard', 2);
							}}
							icon={<CopyOutlined />}>
							Copy URL
						</Button>
						{authenticatedButtons && authenticatedButtons(job)}
					</div>
				</div>
				<p className="text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
					{job?.company_name && capitalizeFirstLetter(job?.company_name)}
					<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</span>
				</p>
				<span className="text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
					{capitalizeFirstLetter(job.title)}
				</span>
				<div className="flex items-center gap-2">
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.engagement_type}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.category}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.job_type}
					</div>
				</div>
				<hr className="mt-4 mb-2" />
				<div className="flex items-center justify-between">
					<div>
						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${parseFloat(job.max_monthly_salary).toString()}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${parseFloat(job.max_hourly_rate).toString()}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}

						<p className="text-base font-semibold opacity-80" style={{color: permColors.charcoal}}>
							{job.country}
						</p>
					</div>
				</div>
			</div>
			<br />
		</React.Fragment>
	);
}
