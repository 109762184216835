import React from 'react';
import {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {GoogleLogin} from '@react-oauth/google';
import Loading from '../Loaders/Loading';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {getUser, googleAuthApi, signUpApi} from '../../api/authApi';
import {useMediaQuery} from '@mui/material';
import {ROUTES} from '../../utils/ROUTES';
import {Alert, Button, Checkbox, Input} from 'antd';
import {GoogleCircleFilled, LockOutlined, MailOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth';
import {getNotificationsApi} from '../../api/notificationsApi';
import {useAtom} from 'jotai';
import {unreadNotificationsAtom} from '../../utils/atoms';
import {COOKIES} from '../../utils/cookie-names';
import Cookies from 'js-cookie';
import axios from 'axios';
import {mainUrl} from '../../api/Urls';

export default function Signup() {
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [error, setError] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [googleEmailError, setGoogleEmailError] = useState('');
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const smallMatches = useMediaQuery('(max-width: 525px)');
	const [, setNotifications] = useContext(NotificationContext);
	const [, setUnreadNotifications] = useAtom(unreadNotificationsAtom);

	function clearErrors() {
		setError(false);
		setGoogleEmailError('');
	}

	async function googleLogin(response) {
		try {
			console.log(response);
			setLoading(true);

			const googleAuthApiResponse = await googleAuthApi(response);
			const {data} = googleAuthApiResponse;

			console.log(data);
			clearErrors();
			localStorage.setItem('token', data.tokens.refresh);
			localStorage.setItem('accessToken', data.tokens.access);

			const userResponse = await getUser();
			setCurrentUser(userResponse.data);
			setLoading(false);
			navigate('/');
		} catch (error) {
			if (error.response.data) {
				clearErrors();
				setGoogleEmailError(error.response.data.detail);
			}
		}
	}

	async function signUp(e) {
		e.preventDefault();
		if (password === rePassword) {
			setLoading(true);

			if (password === rePassword) {
				setLoading(true);
				const result = await signUpApi(email.toLowerCase(), password);

				if (result.success) {
					setError('');
					setLoading(false);
					navigate('/confirmemail', {state: {email: result.email}});
				} else {
					setError(result.error);
					setLoading(false);
				}
			}
		} else {
			setPasswordError(true);
			setLoading(false);
		}
	}

	const firebaseGoogleAuthApiLocal = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();

		try {
			const result = await signInWithPopup(auth, provider);
			setLoading(true);
			const user = result.user;
			const idToken = await user.getIdToken();
			const response = await axios.post(mainUrl + '/auth/firebase-google-auth/', {
				idToken: idToken,
			});
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error('Error during Google sign-in:', error);
			setLoading(false);
		}
	};

	async function getNotifications() {
		if (localStorage.getItem('accessToken')) {
			const res = await getNotificationsApi();
			console.log(res);
			setNotifications(res);
			setUnreadNotifications(res?.filter((notif) => notif.read === false).length);
			console.log('Successfully fetched notifications');
		}
	}

	const loginRedirect = () => {
		if (Cookies.get(COOKIES.unauthorized_redirect)) {
			navigate(Cookies.get(COOKIES.unauthorized_redirect));
			Cookies.remove(COOKIES.unauthorized_redirect);
		} else if (Cookies.get(COOKIES.post_job_redirect)) {
			navigate(Cookies.get(COOKIES.post_job_redirect));
			Cookies.remove(COOKIES.post_job_redirect);
		} else {
			navigate('/');
		}
	};

	async function firebaseGoogleLogin() {
		try {
			const response = await firebaseGoogleAuthApiLocal();
			if (!response?.refresh || !response?.access) {
				throw new Error('Invalid response from Google authentication');
			}
			console.log(response);
			console.log('refresh: ', response.refresh);
			localStorage.setItem('token', response.refresh);
			localStorage.setItem('accessToken', response.access);
			const userResponse = await getUser();
			if (!userResponse?.data) {
				throw new Error('Invalid user response');
			}
			setCurrentUser(userResponse.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			console.error(error);
			clearErrors();
			setGoogleEmailError(error?.response?.data?.detail || 'An error occurred during login');
			setLoading(false);
		}
	}

	return (
		<div>
			<form onSubmit={signUp}>
				<div className="text">
					<h1>Create your account</h1>
					<h2 className="text-lg">Join our community.</h2>
				</div>

				<div>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Email
					</p>
					<Input
						type="email"
						placeholder="Enter your email"
						required
						onChange={(e) => setEmail(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<MailOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					{error && <Alert message={error} type="error" showIcon className="mb-2" />}
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Password
					</p>
					<Input
						type="password"
						placeholder="Enter your password"
						required
						onChange={(e) => setPassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Confirm Password
					</p>
					<Input
						type="password"
						placeholder="Confirm your password"
						required
						onChange={(e) => setRePassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					{passwordError && <Alert message="Passwords do not match" type="error" showIcon className="mt-3 mb-2" />}
					<Checkbox required className="mb-2 mt-2">
						I agree to and understand the{' '}
						<Link target="_blank" to={ROUTES.TERMS_AND_CONDITIONS}>
							Terms and Service.
						</Link>
					</Checkbox>
				</div>

				<Button
					htmlType="submit" // This specifies that the button is a submit button
					className="ButtonLinkBlue min-h-fit w-full m-0"
					loading={loading}
					disabled={loading || !email || !password || !rePassword}>
					Sign up
				</Button>

				<div className="divider-container">
					<div className="divider">
						<hr />
						<span className="text-xs">OR</span>
						<hr />
					</div>
				</div>
				{/* <div className="google-button">
					<GoogleLogin
						onSuccess={(credentialResponse) => {
							googleLogin(credentialResponse);
						}}
						onError={() => {
							console.log('Login Failed');
						}}
						size="large"
						theme="filled"
						shape="square"
						width={smallMatches ? '310px' : '900px'}
					/>
				</div> */}
				<Button onClick={firebaseGoogleLogin} icon={<GoogleCircleFilled className="text-2xl py-1" style={{color: '#4285F4'}} />} className="w-full h-fit flex items-center justify-center">
					Continue with Google
				</Button>
			</form>
		</div>
	);
}
