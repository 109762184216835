import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './HowItWorks.scss';
import {Helmet} from 'react-helmet';
import firstSegment from '../../assets/images/firstSegment.png';
import peopleLaughing from '../../assets/images/peopleLaugh.jpeg';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import {useMediaQuery} from '@mui/material';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';

const SegmentPart = ({Icon, title, text}) => {
	return (
		<div className="segment-part mb-4">
			<Icon className="icon" />
			<div className="segment-part-text">
				<h3 className="text-lg font-semibold">{title}</h3>
				<p className="text-sm">{text}</p>
			</div>
		</div>
	);
};

const AboutUs = () => {
	const matches = useMediaQuery('(min-width: 1050px)');
	return (
		<div>
			<Helmet>
				<title>How It Works | Permworks</title>
				<meta name="description" content="Learn how Permworks operates. Understand the process of discovering jobs or posting job listings, and how to connect with global talent." />
			</Helmet>
			<Navbar2 />
			<div className="how-it-works-container">
				<h1>How It Works</h1>
				<div className="first-segment my-8">
					<div className="first-segment-content mx-4">
						<h2 className="mb-2 text-2xl font-semibold">Job Ad Posting</h2>
						<p className="mb-3 text-lg">Post an ad on Permworks' jobs board for any role</p>
						<SegmentPart
							Icon={WorkOutlineIcon}
							title="1. Post a job ad"
							text="Create an account and post detailed job listings within minutes for any role in the Philippines. Our streamlined process makes it easy to specify requirements, responsibilities and qualifications."
							className="mb-4"
						/>
						<SegmentPart
							Icon={PeopleAltOutlinedIcon}
							title="2. Screen and select"
							text="Review applications through your personalized employer dashboard. Message candidates directly and efficiently manage your recruitment pipeline all in one place."
							className="mb-4"
						/>
						<SegmentPart Icon={ThumbUpAltOutlinedIcon} title="3. Hire" text="Once you've found the perfect candidate, extend an offer and begin the onboarding process." className="mb-6" />
						<a href={ROUTES.POST_JOB} rel="noreferrer" target="_blank" className="mt-4 flex items-center gap-2 hover:gap-4 duration-300">
							Post a job <ArrowForwardIcon className="arrow" />
						</a>
					</div>
					{matches && <img src={firstSegment} alt="first segment" className="first-segment-image mt-4" />}
				</div>

				{/* <div className="second-segment">
					<div className="first-segment-content">
						<h2 className="text-2xl mb-2 font-semibold">End-To-End Recruitment</h2>
						<p className="text-lg mb-3">Let the experts take care of the entire recruitment process</p>
						<SegmentPart
							Icon={FactCheckOutlinedIcon}
							title="1. Review + scope of role"
							text="We review and advertise a comprehensive job description, ensuring maximum exposure to attract qualified candidates across diverse channels online"
						/>
						<SegmentPart
							Icon={PeopleAltOutlinedIcon}
							title="2. Screen and select"
							text="Selection and Initial Assessment: We rigorously screen received applications, shortlist candidates based on specified criteria, and conduct initial interviews assessing their suitability, skills, and cultural fit"
						/>
						<SegmentPart
							Icon={TvOutlinedIcon}
							title="3. Hire"
							text="Finalist Selection and Joint Interviews: Post technical assessments, we present a curated list of top candidates for joint interviews, coordinating the process for efficient and seamless integration into your team"
						/>
						<a href={EXTERNAL_ROUTES.PERMHUNT} rel="noreferrer" target="_blank">
							Learn more <ArrowForwardIcon className="arrow" />
						</a>
					</div>
					{matches && <img src={peopleLaughing} alt="first segment" className="first-segment-image" />}
				</div> */}
			</div>

			<Footer />
		</div>
	);
};

export default AboutUs;
