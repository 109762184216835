export const philippineCities = [
	'Angeles City (Clark Freeport Zone)',
	'Antipolo City',
	'Bacolod City [BAC]',
	'Baguio City [BGO]',
	'Balanga City',
	'Batangas City [BAT]',
	'Butuan City [BUT]',
	'Cabanatuan City',
	'Cagayan de Oro City [CDO] (Uptown, Downtown)',
	'Calamba City (Laguna Technopark)',
	'Calapan City',
	'Calbayog City',
	'Caloocan City',
	'Canlaon City',
	'Cauayan City',
	'Cavite City',
	'Cebu City [CEB] (Cebu Business Park, IT Park)',
	'Cotabato City [CBO]',
	'Dagupan City [DAG]',
	'Danao City',
	'Dapitan City',
	'Davao City [DVO] (Lanang Business District)',
	'Dipolog City',
	'Dumaguete City [DGT]',
	'General Santos City [GSC]',
	'Gingoog City',
	'Iligan City [IGN]',
	'Iloilo City [ILO] (Iloilo Business Park)',
	'Iriga City',
	'Isabela City',
	'Kidapawan City',
	'Koronadal City',
	'Laoag City [LAO]',
	'Lapu-Lapu City (Mactan Economic Zone)',
	'Las Piñas City (Vista Mall District)',
	'Legazpi City [LGP]',
	'Lipa City',
	'Lucena City [LCN]',
	'Makati City [MKT] (Ayala Center, Salcedo Village, Legaspi Village, Poblacion)',
	'Malabon City',
	'Malaybalay City',
	'Malolos City',
	'Mandaluyong City (Ortigas Center)',
	'Mandaue City (North Reclamation Area)',
	'Manila [MNL] (Binondo, Ermita, Malate, Intramuros, Paco, Quiapo, San Miguel)',
	'Marawi City',
	'Marikina City',
	'Masbate City',
	'Muntinlupa City (Alabang Commercial District, Filinvest City)',
	'Naga City [NGC]',
	'Navotas City',
	'Olongapo City [OLP] (Subic Bay Freeport Zone)',
	'Ormoc City',
	'Oroquieta City',
	'Ozamiz City [OZC]',
	'Pagadian City',
	'Palayan City',
	'Panabo City',
	'Parañaque City (Bay City, Entertainment City)',
	'Pasay City (Mall of Asia Complex)',
	'Pasig City (Ortigas Center, Kapitolyo)',
	'Puerto Princesa City [PPS]',
	'Quezon City [QC] (Cubao, Eastwood City, Triangle Park, Araneta Center, North Triangle Business District, UP-Technohub)',
	'Roxas City [RXS]',
	'Sagay City',
	'San Carlos City (Negros Occidental)',
	'San Carlos City (Pangasinan)',
	'San Fernando City (La Union) [SFU]',
	'San Fernando City (Pampanga) [SFP]',
	'San Jose City',
	'San Jose del Monte City',
	'San Juan City (Greenhills Commercial Center)',
	'San Pablo City',
	'Santa Rosa City (Nuvali, Greenfield City)',
	'Santiago City',
	'Silay City',
	'Surigao City [SUG]',
	'Tabaco City',
	'Tacloban City [TAC]',
	'Tacurong City',
	'Tagaytay City',
	'Tagbilaran City [TAG]',
	'Taguig City (Bonifacio Global City [BGC], McKinley Hill, McKinley West)',
	'Tagum City',
	'Talisay City (Cebu)',
	'Talisay City (Negros Occidental)',
	'Tanauan City',
	'Tandag City',
	'Tangub City',
	'Tanjay City',
	'Tarlac City [TAR]',
	'Toledo City',
	'Trece Martires City',
	'Tuguegarao City [TUG]',
	'Urdaneta City',
	'Valencia City',
	'Valenzuela City',
	'Victorias City',
	'Vigan City [VGN]',
	'Zamboanga City [ZAM]',
];
