import React, {useContext, useState, useRef, useEffect} from 'react';
import {UserContext} from '../../utils/UserContext';
import {editProfileApi, editProfileImage} from '../../api/profileApi';
import {countries} from '../../utils/arrayData/CountryArray';
import {Helmet} from 'react-helmet';
import {Layout, Typography, Form, Input, Select, Button, Avatar, Upload, message, Spin, Card} from 'antd';
import {EditOutlined, SaveOutlined, CloseOutlined, UploadOutlined, LockOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import NewPasswordPopup from '../../components/AuthComponents/NewPasswordPopup';
import Navbar from '../../components/Navbars/Navbar2';
import {isJobSeeker} from '../../utils/variables/userVariables';

const {Content} = Layout;
const {Title, Text} = Typography;

export default function EditProfile() {
	const [currentUser] = useContext(UserContext);
	const [form] = Form.useForm();
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [popup, setPopup] = useState(false);
	const fileInputRef = useRef(null);

	useEffect(() => {
		if (currentUser?.profile) {
			form.setFieldsValue({
				company_name: currentUser.profile.company_name,
				first_name: currentUser.profile.first_name,
				last_name: currentUser.profile.last_name,
				country: currentUser.profile.country,
			});
		}
	}, [currentUser, form]);

	const handleImageChange = async (e) => {
		if (e.target.files && e.target.files[0]) {
			setLoading(true);
			try {
				await editProfileImage(e.target.files[0]);
				message.success('Profile image updated successfully');
				window.location.reload();
			} catch (error) {
				message.error('Failed to update profile image');
			} finally {
				setLoading(false);
			}
		}
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			await editProfileApi(values);
			message.success('Profile updated successfully');
			setEdit(false);
			window.location.reload();
		} catch (error) {
			message.error('Failed to update profile');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout style={{minHeight: '100vh', backgroundColor: permColors.backgroundLightCream}}>
			<Helmet>
				<title>Edit Profile | Permworks</title>
				<meta name="description" content="Update your Permworks profile. Keep your skills, experience, and other details up to date." />
			</Helmet>
			<Navbar />
			<div style={{margin: '0 auto'}} className="p-4 w-[600px] max-w-full">
				<Card>
					<Spin spinning={loading}>
						<Title level={2} style={{marginBottom: '30px'}}>
							Edit Profile
						</Title>
						<Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={currentUser?.profile}>
							<div className="flex items-center flex-wrap mb-4">
								<Avatar size={100} src={currentUser?.profile?.profile_image} style={{marginRight: '20px'}} />
								<input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleImageChange} />
								<Button icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()}>
									{isJobSeeker(currentUser) ? 'Change Profile Picture' : 'Change Company Logo'}
								</Button>
							</div>

							{edit ? (
								<>
									<Form.Item name="company_name" label="Company Name" rules={[{required: true, message: 'Please enter your company name'}]}>
										<Input />
									</Form.Item>

									<Form.Item name="first_name" label="First Name" rules={[{required: true, message: 'Please enter your first name'}]}>
										<Input />
									</Form.Item>

									<Form.Item name="last_name" label="Last Name" rules={[{required: true, message: 'Please enter your last name'}]}>
										<Input />
									</Form.Item>

									<Form.Item name="country" label="Country" rules={[{required: true, message: 'Please select your country'}]}>
										<Select>
											{countries.map((country) => (
												<Select.Option key={country} value={country}>
													{country}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</>
							) : (
								<>
									<div style={{marginBottom: '20px'}}>
										<Text strong>Company Name:</Text>
										<Text style={{marginLeft: '10px'}}>{currentUser?.profile?.company_name || 'N/A'}</Text>
									</div>

									<div style={{marginBottom: '20px'}}>
										<Text strong>First Name:</Text>
										<Text style={{marginLeft: '10px'}}>{currentUser?.profile?.first_name}</Text>
									</div>
									<div style={{marginBottom: '20px'}}>
										<Text strong>Last Name:</Text>
										<Text style={{marginLeft: '10px'}}>{currentUser?.profile?.last_name}</Text>
									</div>
									<div style={{marginBottom: '20px'}}>
										<Text strong>Country:</Text>
										<Text style={{marginLeft: '10px'}}>{currentUser?.profile?.country}</Text>
									</div>
								</>
							)}

							{!edit ? (
								<Button type="primary" icon={<EditOutlined />} onClick={() => setEdit(true)} style={{marginRight: '10px'}}>
									Edit Profile
								</Button>
							) : (
								<>
									<Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading} style={{marginRight: '10px'}}>
										Save Changes
									</Button>
									<Button icon={<CloseOutlined />} onClick={() => setEdit(false)}>
										Cancel
									</Button>
								</>
							)}
						</Form>

						{currentUser?.auth_provider === 'email' && (
							<Button type="link" icon={<LockOutlined />} style={{marginTop: '20px'}} onClick={() => setPopup(true)}>
								Change Password
							</Button>
						)}
					</Spin>
				</Card>
			</div>
			<NewPasswordPopup showPopup={popup} closePopup={() => setPopup(false)} />
		</Layout>
	);
}
