import React, {useContext, useEffect, useRef, useState} from 'react';
import './inbox.scss';
import {getConversationsApi, getMessagesApi, sendMessageApi} from '../../api/messagingApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import {UserContext} from '../../utils/UserContext';
import {formatDate2} from '../../utils/DateFunctions';
import {AiOutlineSend} from 'react-icons/ai';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Card from 'react-bootstrap/Card';
import {ArrowBack} from '@material-ui/icons';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hidden from '@mui/material/Hidden';
import Placeholder from 'react-bootstrap/Placeholder';
import {onMessage} from 'firebase/messaging';
import {messaging} from '../../firebase';
import {permColors} from '../../utils/colors';
import {Input, Spin} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ChatBox from './ChatBox';
import {useAtom} from 'jotai';
import {conversationsAtom, conversationsTriggeredAtom, displayedConversationsAtom} from '../../utils/atoms/dashboardAtoms';
import {wsUrl} from '../../api/Urls';
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MessageInput = ({message, setMessage, sendMessage, sendMessageLoading}) => (
	<div className="textarea-container">
		<TextArea value={message} onChange={(e) => setMessage(e.target.value)} rows={4} className="message-textarea" autoSize disabled={sendMessageLoading} />
		{sendMessageLoading ? <Spin size="small" className="send-icon bottom-2 cursor-wait mb-1 mr-1" /> : <AiOutlineSend className="send-icon font-icon" onClick={sendMessage} />}
	</div>
);

const UserListItem = ({user, conversation, selectedConversation, onSelect}) => (
	<div className={`user-item mb-2 ${selectedConversation === conversation.id ? 'selected' : ''}`} onClick={() => onSelect(conversation.id, user)}>
		<div style={{display: 'flex', alignItems: 'flex-start'}}>
			<ListItemAvatar>
				<AvatarWithLoading
					alt={`${user?.profile?.first_name ?? 'Unknown'} ${user?.profile?.last_name ?? 'Applicant'}`}
					src={user?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
				/>
			</ListItemAvatar>
			<div>
				<div className="heading-name text-sm font-semibold">{`${user?.profile?.first_name ?? 'Unknown'} ${user?.profile?.last_name ?? 'Applicant'}`}</div>
				<div className="text-sm flex flex-col">
					{user?.profile?.company_name && user?.profile?.company_name !== 'undefined' && <span className="text-xs font-semibold">{user?.profile?.company_name}</span>}
					<span className="text-xs">{'Created on ' + formatDate2(conversation.created_at) ?? 'No phone number provided'}</span>
				</div>
			</div>
		</div>
	</div>
);

export default function Inbox() {
	const [originalConversations, setOriginalConversations] = useAtom(conversationsAtom);
	const [conversationsTriggered, setConversationsTriggered] = useAtom(conversationsTriggeredAtom);
	const [displayedConversations, setDisplayedConversations] = useAtom(displayedConversationsAtom);
	const [messagesContent, setMessagesContent] = useState([]);
	const [loading, setLoading] = useState(true);
	const [currentUser] = useContext(UserContext);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [message, setMessage] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [open, setOpen] = useState(false);
	const [sendMessageLoading, setSendMessageLoading] = useState(false);
	const [fetchingConversations, setFetchingConversations] = useState(false);
	const [fetchingMessages, setFetchingMessages] = useState(false);

	const [notificationsAvailable, setNotificationsAvailable] = useState(true);

	const ws = useRef(null);

	// useEffect(() => {
	// 	onMessage(messaging, (payload) => {
	// 		getMessagesApi(selectedConversation).then((res2) => {
	// 			setMessagesContent(res2);
	// 			setMessage('');
	// 		});
	// 	});
	// }, [selectedConversation]);

	useEffect(() => {
		if (currentUser?.id) {
			// Include authentication token in the WebSocket URL
			const token = localStorage.getItem('accessToken');
			ws.current = new WebSocket(`${wsUrl}/ws/notifications/?token=${token}`);

			ws.current.onopen = () => {
				console.log('WebSocket Connected Successfully');
				ws.current.send(
					JSON.stringify({
						type: 'connect',
						user_id: currentUser.id,
					})
				);
			};

			ws.current.onmessage = (event) => {
				console.log('Raw WebSocket message received');
				// Fetch new messages when we receive a notification
				if (selectedConversation) {
					getMessagesApi(selectedConversation).then((res2) => {
						setMessagesContent(res2);
						setMessage('');
					});
				}
			};

			ws.current.onerror = (error) => {
				console.error('WebSocket connection error');
				// Implement reconnection logic if needed
				setTimeout(() => {
					// Attempt to reconnect
				}, 5000);
			};

			ws.current.onclose = (event) => {
				console.log('WebSocket connection closed');
				// Implement reconnection logic if needed
				if (event.code !== 1000) {
					// Normal closure
					setTimeout(() => {
						// Attempt to reconnect
					}, 5000);
				}
			};

			return () => {
				if (ws.current) {
					ws.current.close(1000, 'Component unmounting');
				}
			};
		}
	}, [currentUser?.id, selectedConversation]);

	const handleClose = () => {
		setOpen(false);
		setSelectedConversation(null);
	};

	const fetchConversations = async () => {
		if (fetchingConversations) return;
		setFetchingConversations(true);
		const res = await getConversationsApi();
		if (res === 'error') window.location.reload();
		setOriginalConversations(res);
		setDisplayedConversations(res);
		setFetchingConversations(false);
		setLoading(false);
		setConversationsTriggered(true);
	};

	const sendMessage = async () => {
		if (sendMessageLoading || !message) return;
		setSendMessageLoading(true);
		await sendMessageApi(selectedConversation, message);
		const res2 = await getMessagesApi(selectedConversation);
		setMessagesContent(res2);
		setMessage('');
		setSendMessageLoading(false);
	};

	const fetchMessages = async (id, user) => {
		if (id === selectedConversation || fetchingMessages) return;
		setFetchingMessages(true);
		setMessagesContent([]);
		setSelectedConversation(null);
		setOpen(true);
		const res = await getMessagesApi(id);
		setSelectedUser(user);
		setMessagesContent(res);
		setSelectedConversation(id);
		setFetchingMessages(false);
	};

	const filter = (conversations, filterText) => {
		if (!filterText) return conversations;
		const lowerCaseFilterText = filterText.toLowerCase().trim();
		return conversations.filter((conversation) =>
			conversation.users.some((user) => {
				if (user.email === currentUser.email) return false;
				const {first_name, last_name} = user.profile || {};
				const fullName = `${first_name?.toLowerCase() ?? ''} ${last_name?.toLowerCase() ?? ''}`;
				return fullName.includes(lowerCaseFilterText);
			})
		);
	};

	const runFilter = (e) => {
		const txt = e.target.value || '';
		setDisplayedConversations(filter(originalConversations, txt));
	};

	const fetchInitialConversations = async () => {
		if (fetchingConversations) return;
		setFetchingConversations(true);
		const res = await getConversationsApi();
		if (res === 'error') window.location.reload();
		setOriginalConversations(res);
		setDisplayedConversations(res);
		setFetchingConversations(false);
		setLoading(false);
		setConversationsTriggered(true);

		// Select first conversation if available
		if (res?.length > 0) {
			const firstUser = res[0].users.find((user) => user.id !== currentUser.id);
			fetchMessages(res[0].id, firstUser);
		}
	};

	useEffect(() => {
		if (!conversationsTriggered) {
			fetchInitialConversations();
		} else {
			setLoading(false);
		}
	}, []);

	if (loading) return <LoadingPartial />;

	return (
		<div className="dashboardComponent">
			<div className="outerContainer">
				<div className="messagingContainer">
					<Hidden mdUp>
						<DialogPopup
							open={open}
							handleClose={handleClose}
							selectedUser={selectedUser}
							messagesContent={messagesContent}
							currentUser={currentUser}
							setMessage={setMessage}
							message={message}
							sendMessage={sendMessage}
							sendMessageLoading={sendMessageLoading}
						/>
					</Hidden>
					<Row>
						<Col className="leftSide">
							<Card className="cardDesign max-h-full overflow-y-auto">
								<Card.Header className="cardHeaderDesign pl-0 mb-3" style={{borderBottom: '1px solid #f5f5f5', color: permColors.darkCharcoal}}>
									<span className="text-base">Messages</span>
								</Card.Header>

								<Input type="text" placeholder="Filter Names" onChange={runFilter} className="mb-4" />
								<p className="mb-2 text-sm pl-1" style={{color: permColors.charcoal}}>
									{displayedConversations?.length} Conversation{displayedConversations?.length !== 1 && 's'}
								</p>
								<div className="p-1 users-list">
									{displayedConversations?.length > 0 ? (
										displayedConversations.map((conversation) => (
											<div key={conversation.id}>
												{conversation.users
													.filter((user) => user?.id !== currentUser.id)
													.map((user, index) => (
														<UserListItem key={index} user={user} conversation={conversation} selectedConversation={selectedConversation} onSelect={fetchMessages} />
													))}
											</div>
										))
									) : (
										<p className="text-sm p-1">No conversations yet.</p>
									)}
								</div>
							</Card>
						</Col>
						<Hidden mdDown>
							<Col className="rightSide">
								<Card className="cardDesign flex h-full">
									<Card.Header className="cardHeaderDesign pl-0 mb-0" style={{borderBottom: '1px solid #f5f5f5', color: permColors.darkCharcoal}}>
										<div className="flex items-center">
											<ListItemAvatar>
												<AvatarWithLoading
													alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
													src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
												/>
											</ListItemAvatar>
											<div className="flex row">
												<span className="text-sm">
													{selectedConversation && selectedUser
														? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}`
														: 'Select a conversation'}
												</span>
												<span className="text-sm font-normal">
													{selectedConversation && selectedUser ? (selectedUser?.profile?.company_name !== 'undefined' ? selectedUser?.profile?.company_name : '') : ''}
												</span>
											</div>
										</div>
									</Card.Header>
									{selectedConversation && selectedUser ? (
										<>
											<Card.Body style={{overflowY: 'auto'}} className="rightColumnn flex-1 grow">
												<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
											</Card.Body>
											<Card.Footer
												className="d-flex align-items-center justify-content-between cardHeaderDesign m-0 py-3 px-0"
												style={{borderTop: `1px solid ${permColors.backgroundGrey}`}}>
												<MessageInput message={message} setMessage={setMessage} sendMessage={sendMessage} sendMessageLoading={sendMessageLoading} />
											</Card.Footer>
										</>
									) : (
										<>
											{!fetchingMessages ? (
												<Card.Body style={{maxHeight: '600px', overflowY: 'auto'}}>
													<Card.Text className="text-sm">
														Select a conversation to view messages.
														<br />
														<Placeholder xs={6} />
														<Placeholder className="w-75" />
														<Placeholder style={{width: '25%'}} />
													</Card.Text>
												</Card.Body>
											) : (
												<Spin size="large" className="my-auto" />
											)}
										</>
									)}
								</Card>
							</Col>
						</Hidden>
					</Row>
				</div>
			</div>
		</div>
	);
}

export function AvatarWithLoading({src, ...otherProps}) {
	const [loadedSrc, setLoadedSrc] = useState('/static/images/avatar/default.jpg');

	useEffect(() => {
		const image = new Image();
		image.src = src;
		image.onload = () => {
			setLoadedSrc(src);
		};
	}, [src]);

	return <Avatar src={loadedSrc} {...otherProps} sx={{border: '1px solid ' + permColors.backgroundGrey}} />;
}

const DialogPopup = ({handleClose, open, selectedUser, messagesContent, currentUser, setMessage, message, sendMessage, sendMessageLoading}) => (
	<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
		<AppBar sx={{position: 'sticky', backgroundColor: permColors.white}} className="shadow-sm">
			<Toolbar>
				<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="back">
					<ArrowBack style={{color: permColors.darkCharcoal}} />
				</IconButton>
				<div className="flex items-center pl-3">
					<ListItemAvatar>
						<AvatarWithLoading
							alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
							src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
						/>
					</ListItemAvatar>
					<div className="flex row text-left" style={{color: permColors.charcoal}}>
						<span className="text-sm pl-2">
							{selectedUser ? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}` : 'Select a conversation'}
						</span>
						<span className="text-sm font-normal pl-2">{selectedUser ? (selectedUser?.profile?.company_name !== 'undefined' ? selectedUser?.profile?.company_name : '') : ''}</span>
					</div>
				</div>
			</Toolbar>
		</AppBar>
		<Card.Body style={{backgroundColor: permColors.white}}>
			<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
		</Card.Body>
		<Card.Footer className="d-flex align-items-center justify-content-between m-0 p-2" style={{position: 'sticky', bottom: '0', backgroundColor: '#fffffe'}}>
			<MessageInput message={message} setMessage={setMessage} sendMessage={sendMessage} sendMessageLoading={sendMessageLoading} />
		</Card.Footer>
	</Dialog>
);
