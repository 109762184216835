import {CheckCircleOutlined} from '@ant-design/icons';
import {Badge, Button, Tag} from 'antd';
import {AiOutlineArrowRight, AiOutlineCheck} from 'react-icons/ai';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';

export function pricingCard(pricingData: any) {
	return (
		<div className="pricing-cards">
			{pricingData.map((pricing: any, index: number) => {
				const cardContent = (
					<div className={'pricing-card border border-gray-200'}>
						<div className="">
							<h2 className="flex gap-2 text-2xl" style={{color: permColors.darkCharcoal}}>
								{pricing.title}
							</h2>
							<h3 className="text-lg text-darkCharcoal">{pricing.price}</h3>
							<p className="text-charcoal text-sm">{pricing.priceDescription}</p>
						</div>
						<hr className="my-3" />
						<div className="pricing-card-bottom">
							<div>
								{pricing.descriptions.map((description: string, index: number) => (
									<div key={index} className="individual-description">
										<AiOutlineCheck className="icon" />
										<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
											{description}
										</p>
									</div>
								))}
							</div>
							<Button icon={<AiOutlineArrowRight />} type="primary" href={ROUTES.POST_JOB} target="_blank" rel="noreferrer">
								Post a job
							</Button>
						</div>
					</div>
				);

				return pricing?.recommended ? (
					<Badge.Ribbon key={index} text="Recommended" color="blue">
						{cardContent}
					</Badge.Ribbon>
				) : (
					<div key={index}>{cardContent}</div>
				);
			})}
		</div>
	);
}

export function eorCard() {
	return (
		<div>
			<h3 style={{color: permColors.charcoal}} className="font-semibold mb-2 mt-32">
				Employer of Record
			</h3>
			<p className="mb-8">For large scale recruitment and compliance work, the Enterprise plan helps you scale your business quickly with expert tailored services.</p>
			<div className="pricing-cards ">
				<div className="pricing-card max-w-[400px] ">
					<div className="pricing-header">
						<h2 className="flex gap-2">Employer of Record</h2>
						<h3 style={{fontSize: '1.1em'}}>$40-$300 USD per month</h3>
						<p>Price varies by country</p>
					</div>
					<hr className="my-3" />
					<div className="pricing-card-bottom">
						<div>
							<div className="individual-description">
								<AiOutlineCheck className="icon" />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Full employment compliance
								</p>
							</div>
							<div className="individual-description">
								<AiOutlineCheck className="icon" />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Local payroll management
								</p>
							</div>
							<div className="individual-description">
								<AiOutlineCheck className="icon" />
								<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
									Benefits administration
								</p>
							</div>
						</div>
						<a className="linkButtonDarkBlue text-sm" href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT} rel="noreferrer" target="_blank">
							Contact sales
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export function otherSpecializationCards(pricingData: any) {
	return (
		<div className="pricing-cards">
			{pricingData.map((pricing: any, index: number) => (
				<div className="pricing-card h-fit" key={pricing.title}>
					<div className="pricing-header">
						<h2 className="flex gap-2">
							{pricing.title}
							{pricing?.recommended && (
								<Tag color="blue" icon={<CheckCircleOutlined />}>
									Recommended
								</Tag>
							)}
						</h2>
						<h3 style={{fontSize: '1.1em'}}>{pricing.price}</h3>
						<p>{pricing.priceDescription}</p>
					</div>
					<hr className="my-3" />
					<div className="pricing-card-bottom">
						<div>
							{pricing.descriptions.map((description: string, index: number) => (
								<div className="individual-description" key={index}>
									<AiOutlineCheck className="icon" />
									<p className="text-sm sm:text-base mb-1" style={{color: permColors.charcoal}}>
										{description}
									</p>
								</div>
							))}
						</div>
						<Button type="default" href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT} target="_blank" rel="noreferrer" className="text-sm mt-2">
							Contact sales
						</Button>
					</div>
				</div>
			))}
		</div>
	);
}
