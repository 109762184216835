import React, {useEffect, useState, useContext} from 'react';
import {getPageUserApi} from '../../api/authApi';
import {useParams} from 'react-router-dom';
import uploadCircle from '../../assets/images/uploadCircle.png';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import Loading from '../../components/Loaders/Loading';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {formatDate} from '../../utils/DateFunctions';
import MessagePopup from '../../components/Popups/MessagePopup';
import './OtherProfile.scss';
import {Helmet} from 'react-helmet';
import {Card, Avatar, Tag, Button, Alert} from 'antd';
import {MailOutlined, TagOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import {PlaceOutlined, WorkOutline} from '@mui/icons-material';

export default function OtherProfile() {
	const [user, setUser] = useState({});
	const {userId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [popup, setPopup] = useState(false);

	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		async function fetchPageUser() {
			const res = await getPageUserApi(userId);
			setUser(res);
			console.log(res);
			setLoading(false);
		}
		fetchPageUser();
	}, []);

	if (loading) {
		return <Loading />;
	}

	const PermAlert = ({message, type}) => {
		return <Alert message={message} type={type} banner showIcon className="w-fit rounded-md p-3" />;
	};

	return (
		<div className="min-h-screen flex flex-col justify-between bg-backgroundGreyBlue">
			<Helmet>
				<title>{`${user?.profile?.first_name}'s Profile | Permworks`}</title>
				<meta name="description" content={`View ${user?.profile?.first_name}'s profile on Permworks. Explore their skills, experience, and more.`} />
			</Helmet>
			<Navbar />

			<main className="w-full max-w-[1160px] mx-auto px-4 py-5 mb-10">
				<h1 className="text-2xl font-bold text-darkCharcoal py-1 md:text-left">Profile</h1>

				<div className="flex flex-wrap gap-3">
					{/* Left Column */}
					<div className="w-full md:w-[250px] flex flex-col gap-3 flex-grow md:flex-grow-0">
						<Card className="bg-white">
							<div>
								<Avatar size={100} src={user?.profile?.profile_image || uploadCircle} />
								<h2 className="mt-4 mb-2 text-xl font-semibold text-darkCharcoal">
									{capitalizeFirstLetter(user?.profile?.first_name)} {capitalizeFirstLetter(user?.profile?.last_name)}
								</h2>
								<p className="flex gap-2 items-center text-charcoal">
									<PlaceOutlined style={{fontSize: '1.2em'}} /> {user?.profile?.country ?? 'Unknown'}
								</p>

								{user?.email !== currentUser.email && (
									<Button type="default" icon={<MailOutlined />} block onClick={() => setPopup(true)} className="mt-2">
										Message
									</Button>
								)}
							</div>
						</Card>

						<Card className="bg-white">
							<h3 className="text-lg font-semibold mb-4 text-darkCharcoal">Skills</h3>
							{user?.tag?.length > 0 ? (
								<div className="flex flex-wrap gap-2">
									{user?.tag?.map((skill, index) => (
										<Tag key={index} className="bg-backgroundGreyBlue text-charcoal" icon={<TagOutlined className="text-charcoal" />}>
											{skill?.name}
										</Tag>
									))}
								</div>
							) : (
								<PermAlert message="No skills listed" type="info" />
							)}
						</Card>
					</div>

					{/* Right Column */}
					<div className="flex-grow w-full md:w-[650px]">
						<Card className="bg-white">
							<h2 className="text-xl font-bold mb-3 text-darkCharcoal">General Information</h2>

							<div className="space-y-6">
								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal">Work Experience</h3>
									{user?.work_experience?.length > 0 ? (
										<div className="space-y-6">
											{user?.work_experience?.map((work, index) => (
												<div key={index} className="border-l-4 border-blue-500 pl-4">
													<h4 className="text-lg font-semibold">{capitalizeFirstLetter(work?.company_name)}</h4>
													<p className="font-medium">{capitalizeFirstLetter(work?.position_title)}</p>
													<p className="text-gray-600">{work?.country}</p>
													<p className="text-gray-500">
														{work?.from_year} {work?.from_month} - {work?.to_year} {work?.to_month}
													</p>
													<div
														className="mt-2 text-gray-700"
														dangerouslySetInnerHTML={{
															__html: textFormatter(work?.description),
														}}
													/>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No work experience listed" type="info" />
									)}
								</div>

								<hr className="my-6 opacity-10" />

								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal">Education</h3>
									{user?.education?.length > 0 ? (
										<div className="space-y-6">
											{user?.education?.map((item, index) => (
												<div key={index} className="border-l-4 border-green-500 pl-4">
													<h4 className="text-lg font-semibold">{item?.area_of_study && capitalizeFirstLetter(item?.area_of_study)}</h4>
													<p className="font-medium">{item?.school_name && capitalizeFirstLetter(item?.school_name)}</p>
													<p className="text-gray-600">{item?.education_qualification}</p>
													<p className="text-gray-500">
														{item?.year_from} - {item?.year_to}
													</p>
													<div
														className="mt-2 text-gray-700"
														dangerouslySetInnerHTML={{
															__html: textFormatter(item?.description),
														}}
													/>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No education listed" type="info" />
									)}
								</div>

								<hr className="my-6 opacity-10" />

								<div>
									<h3 className="text-lg font-semibold mb-4 text-darkCharcoal">Portfolio</h3>
									{user?.portfolio?.length > 0 ? (
										<div className="space-y-4">
											{user?.portfolio?.map((work, index) => (
												<div key={index} className="border-l-4 border-purple-500 pl-4">
													<h4 className="text-lg font-semibold">{work?.title}</h4>
													<p className="text-gray-700">{work?.description}</p>
													<p className="text-gray-600">Service Type: {work?.service_type}</p>
													<p className="text-gray-600">Category: {work?.category}</p>
													<p className="text-gray-500">{work && `Completed on the ${formatDate(work?.completion_date)}`}</p>
												</div>
											))}
										</div>
									) : (
										<PermAlert message="No portfolio items listed" type="info" />
									)}
								</div>
							</div>
						</Card>
					</div>
				</div>
			</main>

			<MessagePopup
				showPopup={popup}
				closePopup={closePopup}
				title={'Send a message to ' + user?.profile?.first_name}
				textArea={true}
				buttonText={'Send'}
				user={user.id}
				disabledButtonText={'Sent'}
			/>

			{loading && <Loading />}
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
